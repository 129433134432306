import React from 'react'
import { useEpiPagePath } from '@trr/app-shell-data'

import { PageWrapper } from '../Components/PageWrapper'
import StudiestodAnsokanForm from '../Features/StudiestodAnsokanForm/StudiestodAnsokanForm'
import { useGetCurrentStudiestodansokan, usePickEpiContentWithFallback, useStudieansokanUrlParams } from '../Hooks'

const StudiestodAnsokanEditPage = () => {
    const url = useEpiPagePath()
    const { params } = useStudieansokanUrlParams(url)
    const { arendeId, ansokanId } = params
    const { studiestodForm: studiestodFormContent } = usePickEpiContentWithFallback()
    const { data, isFetching, isError, isAktuell, isEjAktuell } = useGetCurrentStudiestodansokan(arendeId, ansokanId)

    return (
        <PageWrapper
            isLoading={isFetching}
            errorLoadingData={isError}
            errorText={studiestodFormContent.valideringsmeddelanden.hamtningsFelText}
            testSelector="studiestod-edit-wrapper"
        >
            {!isFetching && (
                <StudiestodAnsokanForm
                    isEdit
                    isPublicerad={data.isPublicerad}
                    isRedigerbar={data.isRedigerbar && isAktuell}
                    isEjAktuell={isEjAktuell}
                    ejAktuellOrsak={data?.ejAktuell?.orsakKund}
                    ansokanId={ansokanId}
                    ansokan={{
                        isLegacyAnsokan: data.isLegacyAnsokan,
                        ansokanNamn: data.ansokanNamn,
                        harIntygatUppgifter: data.harIntygatUppgifter,
                        motivering: data.motivering,
                        utbildningar: data.utbildningar,
                        utbildningsforutsattning: data.utbildningsforutsattning,
                        villkor: data.villkor,
                        isReadonly: !data.isRedigerbar,
                        shouldPublish: false,
                    }}
                    studiestodNummer={data?.studiestodNummer}
                />
            )}
        </PageWrapper>
    )
}

export default StudiestodAnsokanEditPage
