import React from 'react'

import { RadioButtonGroup } from './RadioButtonGroup'

interface Props {
    label: string
    name: string
    valueType?: 'boolean' | 'J/N'
    testId?: string
    isReadonly?: boolean
    tooltip?: string
}
export const JaNejRadioButtonGroup = ({ label, name, valueType = 'boolean', testId, isReadonly, tooltip }: Props) => (
    <RadioButtonGroup
        label={label}
        name={name}
        valueType={valueType === 'boolean' ? 'boolean' : 'string'}
        testId={testId}
        tooltip={tooltip}
        radios={[
            {
                label: 'Ja',
                value: valueType === 'boolean' ? true : 'J',
                disabled: isReadonly,
            },
            {
                label: 'Nej',
                value: valueType === 'boolean' ? false : 'N',
                disabled: isReadonly,
            },
        ]}
    />
)
