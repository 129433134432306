import { Box, CircularProgress } from '@mui/material'
import React from 'react'

const Loader = () => (
    <Box data-testid="loader" sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
    </Box>
)

export default Loader
