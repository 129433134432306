import { useAuthentication, useUser } from '@trr/app-shell-data'

const useCurrentUser = () => {
    const auth = useAuthentication()
    const user = useUser()
    const isNotEmpty = (value: string) => value !== ''
    const userHasRole = (rolesToCheck = [] as string[]) => {
        const roles = auth?.userRoles ?? []
        return rolesToCheck.some((role) => roles.includes(role))
    }

    const getUserIdToken = () => auth?.idToken || ''

    const getUserMdId = () => auth?.sub || ''

    const userIsLoggedIn = () => isNotEmpty(getUserMdId()) && isNotEmpty(getUserIdToken())

    return {
        user,
        userHasRole,
        getUserMdId,
        getUserIdToken,
        userIsLoggedIn,
    }
}
export default useCurrentUser
