import { Box, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, Stack, Tooltip } from '@mui/material'
import React from 'react'
import { useController, useFormContext } from 'react-hook-form'

import Icons from '../../Icons/Icons'

export interface RadioButton {
    label: string
    value: string | number | boolean
    disabled?: boolean
}
interface Props {
    label: string
    name: string
    valueType?: 'string' | 'number' | 'boolean'
    radios: RadioButton[]
    isReadOnly?: boolean
    testId?: string
    tooltip?: string
}

export const RadioButtonGroup = ({ label, testId, name, radios, valueType = 'string', isReadOnly, tooltip }: Props) => {
    const { watch } = useFormContext()
    const {
        field,
        fieldState: { error },
    } = useController({ name, defaultValue: null })

    const fieldValue = watch(name)
    const getTypedValue = (value: string) => {
        switch (valueType) {
            case 'string':
                return value
            case 'number':
                return value ? parseInt(value) : undefined
            case 'boolean':
                if (value === 'true' || value === 'false') {
                    return value === 'true'
                }
                return undefined
        }
    }

    return (
        <Box mb={3}>
            <FormControl component="fieldset" data-testid={testId || name}>
                {tooltip ? (
                    <Stack direction="row" alignItems={'center'} spacing={1}>
                        <FormLabel sx={(theme) => ({ fontWeight: theme.typography.fontWeightBold })}>{label}</FormLabel>
                        <Tooltip title={tooltip} arrow>
                            <Box>
                                <Icons.Info size="small" />
                            </Box>
                        </Tooltip>
                    </Stack>
                ) : (
                    <FormLabel
                        sx={(theme) => ({
                            fontWeight: theme.typography.fontWeightBold,
                            color: theme.palette.neutral.main,
                            '&.Mui-focused': { color: 'inherit' },
                        })}
                    >
                        {label}
                    </FormLabel>
                )}
                <RadioGroup
                    {...field}
                    onChange={(_, value) => {
                        const typedValue = getTypedValue(value)
                        field.onChange(typedValue)
                        field.onBlur()
                    }}
                >
                    {radios.map((r) => (
                        <FormControlLabel
                            key={`${name}-${r.value.toString()}-radio`}
                            value={r.value}
                            control={<Radio color="primary" disabled={r.disabled} checked={(fieldValue as unknown) === r.value} />}
                            label={r.label}
                            name={name}
                            data-testid={`${name}-${r.value.toString()}`}
                            id={`${name}-${r.value.toString()}`}
                            disabled={isReadOnly}
                        />
                    ))}
                    <FormHelperText error={Boolean(error?.message)}>{error?.message}</FormHelperText>
                </RadioGroup>
            </FormControl>
        </Box>
    )
}
