import Form from '@local/src/Components/Form'
import InfoPopover from '@local/src/Components/InfoPopover'
import Loader from '@local/src/Components/Loader'
import { usePickEpiContentWithFallback } from '@local/src/Hooks'
import { Box, Button, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { FormProvider } from 'react-hook-form'
import useGTMTracking from '@local/src/Hooks/useGTMTracking'
import { useTranslation } from 'react-i18next'

import { BeraknadStudiestart, Fodelsedatum, Kalenderhalvar, Loneniva, RaknesnurraFormResult, Studietakt } from './Components'
import { calculateRaknesnurraResult } from './Helpers/raknesnurraFormResultHelper'
import { useGetRakneParametrar } from './Hooks/useGetRakneParametrar'
import { useRaknesnurraForm } from './Hooks/useRaknesnurraForm'
import { RaknesnurraFormData, RaknesnurraFormResultData } from './Models/RaknesnurraForm.types'

const StudieersattningRaknesnurraForm = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'gtmTracking.raknesnurra' })
    const { rakneparametrar, loadingRakneparametrar } = useGetRakneParametrar()
    const { studiestodRaknesnurra: epiContent } = usePickEpiContentWithFallback()
    const [formResult, setFormResult] = useState<RaknesnurraFormResultData>()
    const formMethods = useRaknesnurraForm(epiContent)
    const { handleSubmit } = formMethods

    const { trackClick } = useGTMTracking()

    const onSubmit = (data: RaknesnurraFormData) => {
        const result = calculateRaknesnurraResult(data, rakneparametrar)
        setFormResult(result)
        trackClick('button', { label: t('berakna_studieersattning') })
    }

    if (loadingRakneparametrar) return <Loader />

    return (
        <FormProvider {...formMethods}>
            <Stack mb={4}>
                <Stack direction="row" spacing={2} alignItems="center" mb={3}>
                    <Typography variant="h2">{epiContent.header}</Typography>
                    <InfoPopover content={epiContent.raknesnurraHeaderTooltip} />
                </Stack>

                <Form onSubmit={handleSubmit(onSubmit)} data-testid="raknesnurra-form">
                    <Stack spacing={3}>
                        <Stack spacing={3}>
                            <Stack spacing={3}>
                                <Fodelsedatum />
                                <Loneniva />
                                <Studietakt />
                                <BeraknadStudiestart />
                            </Stack>
                            <Kalenderhalvar />
                        </Stack>
                        <Box>
                            <Button data-testid="raknesnurra-form-calculate-button" type="submit" data-trackable="false">
                                {epiContent.beraknaButtonText}
                            </Button>
                        </Box>
                    </Stack>
                </Form>

                {formResult && <RaknesnurraFormResult formResult={formResult} />}
            </Stack>
        </FormProvider>
    )
}

export default StudieersattningRaknesnurraForm
