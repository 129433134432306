import { StudiestodApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { storageKeys } from '@local/src/Types/App.types'
import { setLocalStorageState } from '@local/src/Utils/Helpers/StorageHelper'
import { toUrl } from '@local/src/Utils/Helpers/StudiestodHelper'
import { useState } from 'react'
import { ApiResponseModel } from '@local/src/Services/ApiResponseModel'
import useCurrentUser from '@local/src/Hooks/useCurrentUser'

import { StudieAnsokanSentModel, StudiestodAnsokanFormData } from '../Models/StudiestodAnsokanForm.types'
import { createAnsokan } from '../Services/CreateStudiestodAnsokan'
import { updateAnsokan } from '../Services/UpdateStudiestodAnsokan'

export const usePostStudiestodAnsokan = (isEdit: boolean, ansokanId?: string) => {
    const [apiResponse, setApiResponse] = useState<ApiResponseModel<void> | ApiResponseModel<StudiestodApiModel>>(undefined)
    const [isCreated, setIsCreated] = useState(isEdit)
    const [currentAnsokanId, setCurrentAnsokanId] = useState(ansokanId)
    const [redirectToOverview, setRedirectToOverview] = useState(false)
    const { getUserMdId } = useCurrentUser()
    const doRequest = async (currentAnsokanId: string, shouldPublish: boolean, data: StudiestodAnsokanFormData) => {
        setApiResponse(undefined)
        const klientId = getUserMdId()
        const resp = isCreated
            ? await updateAnsokan(klientId, currentAnsokanId, shouldPublish, data)
            : await createAnsokan(klientId, shouldPublish, data)
        setApiResponse(resp)
        return resp
    }

    const publishStudiestodAnsokan = async (data: StudiestodAnsokanFormData) => {
        const resp = await doRequest(currentAnsokanId, true, data)
        if (resp.isSuccess) {
            setLocalStorageState<StudieAnsokanSentModel>(storageKeys.newAnsokanSubmitted, {
                ansokanNamn: data.ansokanNamn,
                sentAt: new Date(),
            })
            setRedirectToOverview(true)
        }
        return resp
    }

    const saveStudiestodAnsokan = async (data: StudiestodAnsokanFormData) => {
        const resp = await doRequest(currentAnsokanId, false, data)
        if (resp.isSuccess) {
            if (!isCreated) {
                const responseModel = resp.response as StudiestodApiModel
                const url = toUrl(window.location.pathname, responseModel.arendeId, responseModel.id)
                window.history.replaceState(null, document.title, url)
                setIsCreated(true)
                setCurrentAnsokanId(responseModel.id)
            }
        }
        return resp
    }

    return { publishStudiestodAnsokan, saveStudiestodAnsokan, apiResponse, redirectToOverview }
}
