import { Button, Stack } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { StudiestodAnsokanFormData } from '../../Models/StudiestodAnsokanForm.types'

import { SubmitSaveButtonEpiContent } from './SubmitSaveButton.content'

interface Props {
    isPublicerad: boolean
    isRedigerbar: boolean
    backToAnsokanListLink: string
    content: SubmitSaveButtonEpiContent
    isEdit: boolean
}

const SubmitSaveButton = ({ isPublicerad, isRedigerbar, backToAnsokanListLink, content, isEdit }: Props): JSX.Element => {
    const {
        formState: { isDirty, isSubmitting },
        setValue,
    } = useFormContext<StudiestodAnsokanFormData>()
    const isDraft = !isPublicerad
    const isNew = !isEdit
    const disabledSubmitButton = (!isDirty && isEdit && !isDraft) || isSubmitting

    const history = useHistory()

    const routeToApplicationsList = () => {
        history.push(backToAnsokanListLink)
    }
    const handleIsUpdate = () => setValue('shouldPublish', false)
    const handleIsCreate = () => setValue('shouldPublish', true)

    return (
        <Stack direction="row" spacing={1}>
            {isRedigerbar && isDraft && (
                <Button
                    variant="outlined"
                    onClick={handleIsUpdate}
                    disabled={isSubmitting}
                    type="submit"
                    data-testid="update-button"
                    data-trackable="false"
                >
                    {content.draftButtonText}
                </Button>
            )}
            {isRedigerbar && isPublicerad && (
                <Button variant="outlined" onClick={routeToApplicationsList} data-testid="cancel-button">
                    {content.cancelButtonText}
                </Button>
            )}
            <Button
                onClick={handleIsCreate}
                data-testid="submit-button"
                disabled={disabledSubmitButton}
                type="submit"
                data-trackable="false"
            >
                {!isPublicerad ? content.sendButtonText : isNew ? content.sendButtonText : content.updateButtonText}
            </Button>
        </Stack>
    )
}

export default SubmitSaveButton
