import Icons from '@local/src/Components/Icons'
import { Box, IconButton, Popover, Typography } from '@mui/material'
import React, { MouseEvent, useState } from 'react'

interface Props {
    content: string
}

const InfoPopover = ({ content }: Props) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const handleOnClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleOnClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)

    return (
        <Box>
            <IconButton sx={{ p: 0 }} onClick={handleOnClick}>
                <Icons.Info color="primary" />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleOnClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{ horizontal: 'center', vertical: 'top' }}
            >
                <Typography p={2} maxWidth={300}>
                    {content}
                </Typography>
            </Popover>
        </Box>
    )
}

export default InfoPopover
