import getConfig from '@local/Utils/getConfig'
import { includes } from 'ramda'

import { IFetchRequestType, ProblemDetails, ValidationError, ValidationErrorsResponse } from './Network.types'

/* eslint-disable */

export const baseUrl = (url: string, isPublicWebApi = false) => {
    if (isPublicWebApi) {
        return `${getConfig().PUBLIC_WEB_API_URL}${url}`
    }

    return url.slice(-4) === 'json' // if request is a json file, retrieve it from public
        ? `${process.env.PUBLIC_URL}${url}`
        : `${getConfig().API_URL}${url}`
}

// construct headers for request
const requestHeaders = ({ id_token, body, method, multipart }: any) => {
    // Directly forcing a 'multipart/form-data' will not automatically generate boundaries, if file is provided, dont set content-type and content-type and boundaries will automatically be generated
    const headerContentType = multipart ? {} : { 'Content-type': 'application/json' }
    return {
        headers: {
            Authorization: `Bearer ${id_token}`,
            'Accept-Language': 'sv',
            ...headerContentType,
            ...shouldUseCache(),
        },
        method,
        body,
    }
}

// append header to bypass cache if cookie set
export const shouldUseCache = () => (sessionStorage.getItem('trr-force-pass') === null ? '' : { 'x-trr-force-pass': true })

// Handle HTTP errors since fetch won't.
export const handleResponse = async (response: any) => {
    const acceptedStatusCodes = [202, 200, 201, 204]
    const { status } = response

    if (includes(status, acceptedStatusCodes)) {
        return [response, null, false]
    }

    const bodyStream = await response.json()
    const problemDetails = bodyStream as ProblemDetails
    if (problemDetails == null) {
        return [response, new Error(status), true]
    }

    if (problemDetails.errorType === 'ValidationException') {
        const validationErrorsResponse = bodyStream as ValidationErrorsResponse
        return [response, new ValidationError(problemDetails, validationErrorsResponse), true]
    }

    return [response, new Error(status), true]
}

// fetch request
export const fetchRequest = ({
    body,
    method,
    id_token,
    _fetch = fetch,
    requestUrl,
    multipart,
    absolutePath,
    isPublicWebApi,
}: IFetchRequestType) => {
    if (absolutePath) {
        return _fetch(absolutePath)
    }
    return _fetch(baseUrl(requestUrl, isPublicWebApi), requestHeaders({ id_token, body, method, multipart }))
}
