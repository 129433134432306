export const getCookie = <T>(cookieName: string): T => {
    const cookie = document.cookie.split(';').find((cookie) => cookie.includes(`${cookieName}=`))
    if (cookie) {
        try {
            return JSON.parse(cookie.split('=')[1]) as T
        } catch {
            return undefined
        }
    }

    return undefined
}

export const setCookie = <T>(value: T, cookieName: string, daysToExpire: number) => {
    const expDate = new Date()
    expDate.setDate(expDate.getDate() + daysToExpire)
    const expires = `expires=${expDate.toUTCString()}`
    document.cookie = `${cookieName}=${JSON.stringify(value)}; ${expires}; path=/`
}
