import useFilePicker, { UseFilePickerOptions } from '@local/src/Hooks/useFilePicker'
import { Button } from '@mui/material'
import React, { Fragment } from 'react'

interface FilePickerButtonProps extends UseFilePickerOptions {
    inputName: string
    multiple?: boolean
}

type Props = FilePickerButtonProps & React.ComponentProps<typeof Button>

const FilePickerButton = ({ ...props }: Props) => {
    const { inputName, multiple = false, fileSizeLimit, validExtensions, onPickValid, onPickError, ...buttonProps } = props
    const { openFileBrowser, inputRef, acceptedExtensions, changeHandler } = useFilePicker({
        fileSizeLimit,
        validExtensions,
        onPickValid,
        onPickError,
    })
    return (
        <Fragment>
            <Button {...buttonProps} onClick={openFileBrowser} />
            <input
                style={{ display: 'none' }}
                type="file"
                name={inputName}
                onChange={changeHandler}
                ref={inputRef}
                accept={acceptedExtensions}
                required
                multiple={multiple}
            />
        </Fragment>
    )
}

export default FilePickerButton
