import { rakneparametrarApi } from '@local/src/Services/apiEndpointHelper'
import { apiGetTryCatchWrapper } from '@local/src/Services/apiRequestHelper'
import { useQuery } from '@tanstack/react-query'

export const useGetRakneParametrar = () => {
    const {
        data: rakneparametrar,
        isLoading: loadingRakneparametrar,
        ...query
    } = useQuery({
        queryKey: ['rakneparametrar'],
        queryFn: () => apiGetTryCatchWrapper('useGetRakneParametrar', rakneparametrarApi.rakneparametrarStudieersattningList),
    })

    return { rakneparametrar, loadingRakneparametrar, ...query }
}
