import Loader from '@local/src/Components/Loader'
import { CalculationResult } from '@local/src/Features/KompletterandeStudiestodRaknesnurraForm/Components'
import {
    AwaitingInputIllustration,
    SomethingWentWrongIllustration,
} from '@local/src/Features/KompletterandeStudiestodRaknesnurraForm/Components/Illustrations'
import { EkonomisktStudiestodApiResponseModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { Fade, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    showLoader: boolean
    showApiResponseError: boolean
    showResults: boolean
    result?: EkonomisktStudiestodApiResponseModel
}

const Results = ({ result, showResults, showApiResponseError, showLoader }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'kompletterandeStudiestodRaknesnurraForm' })

    if (showResults) {
        return (
            <Fade in>
                <CalculationResult result={result} />
            </Fade>
        )
    }

    if (showLoader) {
        return (
            <Fade in>
                <Stack alignItems="center" px={5} py={3} spacing={2}>
                    <Loader />
                    <Typography color={(theme) => theme.palette.text.secondary} textAlign="center">
                        {t('loadingResultText')}
                    </Typography>
                </Stack>
            </Fade>
        )
    }

    if (showApiResponseError) {
        return (
            <Fade in>
                <Stack alignItems="center" px={5} py={3} spacing={2}>
                    <SomethingWentWrongIllustration />
                    <Typography color={(theme) => theme.palette.text.secondary} textAlign="center">
                        {t('formErrors.submitError')}
                    </Typography>
                </Stack>
            </Fade>
        )
    }

    return (
        <Fade in>
            <Stack alignItems="center" px={5} py={3} spacing={2}>
                <AwaitingInputIllustration />
                <Typography alignItems="center" color={(theme) => theme.palette.text.secondary} textAlign="center">
                    {t('awaitingInputText')}
                </Typography>
            </Stack>
        </Fade>
    )
}

export default Results
