import { InputField } from '@local/src/Components/FormComponents'
import { usePickEpiContentWithFallback } from '@local/src/Hooks'
import React from 'react'

const Fodelsedatum = () => {
    const { studiestodRaknesnurra: epiContent } = usePickEpiContentWithFallback()
    return <InputField name="fodelsedatum" label={epiContent.fodelsedatumLabelText} inputType={'number'} />
}

export default Fodelsedatum
