import { svSE } from '@mui/x-date-pickers/locales'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DesktopDatePicker as MuiDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { sv } from 'date-fns/locale'
import React, { useState } from 'react'
import { useController } from 'react-hook-form'

interface IDatePicker {
    name: string
    label: string
    disabled?: boolean
    testId?: string
    minDate?: Date
    maxDate?: Date
}

export const DatePicker = ({ name, label, disabled, testId, minDate, maxDate }: IDatePicker) => {
    const {
        field,
        fieldState: { error },
    } = useController({ name })
    const defaultTestId = testId || name
    const isEnabled = !disabled
    const [open, setOpen] = useState(false)
    const handleOpenDatePicker = () => setOpen(true && isEnabled)
    const handleCloseDatePicker = () => setOpen(false && isEnabled)

    return (
        <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={sv}
            localeText={svSE.components.MuiLocalizationProvider.defaultProps.localeText}
        >
            <MuiDatePicker
                label={label}
                disabled={disabled}
                onChange={field.onChange}
                open={open}
                value={field.value ? new Date(String(field.value)) : null}
                onOpen={handleOpenDatePicker}
                onClose={handleCloseDatePicker}
                minDate={minDate}
                maxDate={maxDate}
                sx={{ mb: 3 }}
                slotProps={{
                    textField: {
                        name: name,
                        error: Boolean(error?.message),
                        helperText: error?.message,
                        fullWidth: true,
                        onClick: handleOpenDatePicker,
                        disabled: disabled,
                        inputProps: {
                            'data-testid': defaultTestId,
                            disabled: disabled,
                        },
                    },
                    popper: {
                        id: `${defaultTestId}-datepicker`,
                    },
                }}
            />
        </LocalizationProvider>
    )
}
