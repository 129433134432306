import { TextArea } from '@local/src/Components/FormComponents'
import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'

import { StudiestodAnsokanFormData } from '../../Models/StudiestodAnsokanForm.types'

import { DinBakgrundEpiContent } from './DinBakgrund.content'

interface Props {
    content: DinBakgrundEpiContent
}
const DinBakgrund = ({ content }: Props) => {
    const { watch } = useFormContext<StudiestodAnsokanFormData>()
    const { isReadonly } = watch()
    return (
        <Stack spacing={2}>
            <Stack spacing={1}>
                <Typography variant="h4">{content.dinBakgrundHeaderText}</Typography>
                <Typography>{content.dinBakgrundLeadText}</Typography>
            </Stack>
            <TextArea
                label={content.kompletteringErfarenhetText}
                name="motivering.kompletteringErfarenhet"
                placeholder={content.kompletteringErfarenhetPlaceholderText}
                disabled={isReadonly}
            />
            <TextArea
                label={content.utbildningsalternativText}
                name="motivering.utbildningsalternativ"
                placeholder={content.utbildningsalternativPlaceholderText}
                disabled={isReadonly}
            />
        </Stack>
    )
}

export default DinBakgrund
