import { InputField, RadioButtonGroup, TextArea } from '@local/src/Components/FormComponents'
import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'

import { StudiestodAnsokanFormData } from '../../Models/StudiestodAnsokanForm.types'

import { MalMedStudiernaEpiContent } from './MalMedStudierna.content'

export interface Props {
    content: MalMedStudiernaEpiContent
}

const MalMedStudierna = ({ content }: Props) => {
    const { watch } = useFormContext<StudiestodAnsokanFormData>()
    const { isReadonly } = watch()
    return (
        <Stack spacing={3}>
            <Stack spacing={1}>
                <Typography variant="h4">{content.malMedStudiernaHeaderText}</Typography>
                <Typography>{content.malMedStudiernaLeadText}</Typography>
            </Stack>

            <InputField
                label={content.ansokanNamnText}
                name="ansokanNamn"
                disabled={isReadonly}
                placeholder={content.ansokanNamnPlaceholderText}
            />

            <RadioButtonGroup
                label={content.utbildningsforutsattningText}
                name="utbildningsforutsattning"
                radios={[
                    {
                        label: content.utbildningsforutsattningVidareutbildningText,
                        value: 'vidareutbildning',
                        disabled: isReadonly,
                    },
                    {
                        label: content.utbildningsforutsattningOmskolningText,
                        value: 'omskolning',
                        disabled: isReadonly,
                    },
                ]}
                valueType="string"
            />

            <TextArea
                name="motivering.nyanstallning"
                placeholder={content.nyanstallningPlaceholderText}
                label={content.nyanstallningText}
                disabled={isReadonly}
            />

            <TextArea
                name="motivering.arbetsmarknad"
                placeholder={content.arbetsmarknadPlaceholderText}
                label={content.arbetsmarknadText}
                disabled={isReadonly}
            />
        </Stack>
    )
}

export default MalMedStudierna
