import { StudiestodAnsokanFormData } from './StudiestodAnsokanForm.types'

export const studiestodFormDefaultData: StudiestodAnsokanFormData = {
  ansokanNamn: '',
  inkomst: undefined,
  utbildningsforutsattning: '',
  motivering: {
    nyanstallning: '',
    arbetsmarknad: '',
    kompletteringErfarenhet: '',
    utbildningsalternativ: '',
  },
  harIntygatUppgifter: undefined,
  villkor: '',
  utbildningar: [],
  isLegacyAnsokan: false,
  isReadonly: false,
  shouldPublish: false,
}
