import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import { useController } from 'react-hook-form'

import { SelectOption } from './Dropdown.types'

interface Props {
    label: string
    name: string
    selectOptions: SelectOption[]
    testId?: string
    isReadOnly?: boolean
}

const Dropdown = ({ label, name, testId, selectOptions, isReadOnly = false }: Props) => {
    const {
        field,
        fieldState: { error },
    } = useController({ name })
    return (
        <FormControl fullWidth error={Boolean(error)} disabled={isReadOnly}>
            <InputLabel sx={{ fontWeight: 400 }}>{label}</InputLabel>
            <Select
                onChange={(event) => {
                    field.onChange(event.target.value)
                }}
                name={name}
                value={field.value ?? ''}
                label={label}
                data-testid={testId}
                inputProps={{ readOnly: isReadOnly }}
                MenuProps={{
                    sx: {
                        maxHeight: '500px',
                    },
                }}
            >
                {selectOptions.map((option) => (
                    <MenuItem key={`${option.value}`} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText>{error?.message}</FormHelperText>
        </FormControl>
    )
}

export default Dropdown
