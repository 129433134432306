import { UtbildningsEnum } from '@local/src/Features/StudiestodAnsokanForm/Components/Utbildningar/UtbildningForm/Models/Utbildningsform.types'
import { StudiestodAnsokanState, StudiestodApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { isNil } from 'ramda'

export const StudiestodAnsokanStatus = {
    EjSkickad: 'ejSkickad',
    Skickad: 'skickad',
    Handlaggs: 'handlaggs',
    Godkand: 'godkand',
    EjAktuell: 'ejAktuell',
} as const

export const mapStudiestodAnsokanStateToStatus = (state: StudiestodAnsokanState) => {
    switch (state) {
        case 1:
            return StudiestodAnsokanStatus.EjSkickad
        case 2:
            return StudiestodAnsokanStatus.Skickad
        case 3:
        case 4:
        case 5:
        case 6:
            return StudiestodAnsokanStatus.Handlaggs
        case 7:
            return StudiestodAnsokanStatus.Godkand
        case 8:
            return StudiestodAnsokanStatus.EjAktuell
        default:
            return StudiestodAnsokanStatus.EjSkickad
    }
}

export const hasUtbildningstyp = (ansokan: StudiestodApiModel, typ: UtbildningsEnum) =>
    !isNil(ansokan?.utbildningar.find((u) => u.utbildningstyp === typ))

export const toUrl = (baseUrl: string, arendeId: string, ansokanId: string) => `${baseUrl}/${arendeId}/${ansokanId}`.replace('//', '/')
