import FilePickerButton from '@local/src/Components/FilePickerButton'
import Icons from '@local/src/Components/Icons'
import { StudieintygEnum } from '@local/src/Features/StudiestodStudieintyg/Components/StudieintygForm/Components/UploadStudieintyg/Models/StudieintygTypes'
import {
    studieintygFileSizeLimitInKb,
    studieintygValidFileFormats,
} from '@local/src/Features/StudiestodStudieintyg/Hooks/useStudieintygFilePicker'
import { StudieintygFormModel } from '@local/src/Features/StudiestodStudieintyg/Models/StudieintygFormModel'
import { FileListError } from '@local/src/Hooks/useFilePicker'
import usePickEpiContentWithFallback from '@local/src/Hooks/usePickEpiContentWithFallback'
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import FileUploadWrapper from '../FileUploadWrapper/FileUploadWrapper'

interface Props {
    fileError: FileListError
    hasError: boolean
    onPick: (files: FileList) => void
    onError: (fileListError: FileListError) => void
    clearFile: (index: number) => void
    resetFiles: () => void
}

const SelectedStudieintygFiles = ({ fileError, hasError, onPick, onError, clearFile, resetFiles }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'gtmTracking.studieintyg' })
    const [showCancelDialog, setShowCancelDialog] = useState(false)
    const {
        watch,
        formState: { isSubmitting },
    } = useFormContext<StudieintygFormModel>()

    const { studieintygFiles } = watch()

    const {
        studiestodStudieintyg: { laddauppstudieintyg: content },
    } = usePickEpiContentWithFallback()

    const handleAvbryt = () => resetFiles()

    const handleShowCancelDialog = () => setShowCancelDialog(true)
    const handleCloseCancelDialog = () => setShowCancelDialog(false)

    return (
        <Card data-testid={'browser-loaded-files'}>
            <CardHeader
                title={content?.laddaUppWorkspaceHeader}
                data-testid="studiestod-list-item-header"
                titleTypographyProps={{ variant: 'h6' }}
                sx={{ pb: 0, mb: 2 }}
            />
            <CardContent sx={{ pt: 0 }}>
                <Box mb={2}>
                    {studieintygFiles.map((file, index) => (
                        <Stack key={index} flexDirection="row" alignItems="center">
                            <IconButton disableRipple aria-label="delete" onClick={() => clearFile(index)} sx={{ pl: 0 }}>
                                <Icons.Delete />
                            </IconButton>
                            <Typography title={file.name}>{file.name}</Typography>
                        </Stack>
                    ))}
                </Box>

                <FileUploadWrapper fileListError={fileError} hasError={hasError}>
                    <FilePickerButton
                        onPickValid={onPick}
                        onPickError={onError}
                        inputName={StudieintygEnum.STUDIEINTYG_FILES}
                        fileSizeLimit={studieintygFileSizeLimitInKb}
                        validExtensions={studieintygValidFileFormats}
                        multiple
                        startIcon={<Icons.Add />}
                        variant="text"
                        disableRipple
                        disableElevation
                        disableFocusRipple
                        disableTouchRipple
                        sx={{
                            p: 1,
                            '&:hover': {
                                backgroundColor: '#fff',
                            },
                            '&:focus': {
                                backgroundColor: '#fff',
                            },
                        }}
                    >
                        {content?.laggTillFilerButtonText}
                    </FilePickerButton>
                </FileUploadWrapper>

                <Stack flexDirection="row" alignItems="center" useFlexGap spacing={1}>
                    <Button variant="outlined" onClick={handleShowCancelDialog} data-gtm-label={t('avbryt')}>
                        {content?.avbrytButtonText}
                    </Button>
                    <Button
                        variant="contained"
                        data-testid={'intyg-submit-button'}
                        disabled={isSubmitting}
                        type="submit"
                        data-trackable="false"
                    >
                        {content?.skickaButtonText}
                    </Button>
                </Stack>

                <Dialog PaperProps={{ 'data-trackable': true }} open={showCancelDialog} onClose={handleCloseCancelDialog} maxWidth="xs">
                    <DialogTitle>{content?.avbrytModalHeader}</DialogTitle>
                    <DialogContent>
                        <Typography>{content?.avbrytModalDescription}</Typography>
                    </DialogContent>
                    <DialogActions sx={{ mb: 2 }}>
                        <Button variant="outlined" onClick={handleCloseCancelDialog}>
                            {content?.avbrytModalNejButton}
                        </Button>
                        <Button variant="contained" onClick={handleAvbryt}>
                            {content?.avbrytModalJaButton}
                        </Button>
                    </DialogActions>
                </Dialog>
            </CardContent>
        </Card>
    )
}

export default SelectedStudieintygFiles
