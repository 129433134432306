export interface StudieintygFormModel {
  avserAr?: string
  termin?: string
  sammanhangandeStudier?: string
  isFornamnEfternamnVisible?: boolean
  isPersonnummerIncluded?: boolean
  isUtbildningensNamnIncluded?: boolean
  isStudieomfattningenIncluded?: boolean
  isDatumForTerminsstartAndTerminsslutIncluded?: boolean
  isStudieintygetDateratTidigastBeforeTerminStartIncluded?: boolean
  studieintygFiles: File[]
}

export const defaultStudieintygFormModelValues: StudieintygFormModel = {
  avserAr: undefined,
  termin: undefined,
  sammanhangandeStudier: undefined,
  isFornamnEfternamnVisible: undefined,
  isPersonnummerIncluded: undefined,
  isUtbildningensNamnIncluded: undefined,
  isStudieomfattningenIncluded: undefined,
  isDatumForTerminsstartAndTerminsslutIncluded: undefined,
  isStudieintygetDateratTidigastBeforeTerminStartIncluded: undefined,
  studieintygFiles: [],
}
