import {
    calculateMaxErsattningFribeloppCsn,
    calculateMaxErsattningInkomstbasbelopp,
    calculateStudieersattningPerDag,
} from '@local/src/Features/StudieersattningRaknesnurraForm/Helpers/ersattningCalculator'
import { ResultatKalenderhalvar } from '@local/src/Features/StudieersattningRaknesnurraForm/Models/RaknesnurraForm.types'
import {
    AgeInkomstBasbeloppApiModel,
    ErsattningProcentApiModel,
    FribeloppIntervallApiModel,
    StudieersattningParametrarApiModel,
} from '@local/src/Services/SwaggerApi/data-contracts'
import { log_info } from '@local/src/Utils/Helpers/logger'

const dagarPerVecka = 5

export type Kalenderhalvar = 1 | 2 | 3 | 4

export interface Rakneparametrar {
    fribelopp: number
    lan: number
    bidrag: number
    dagarPerManad: number
    minAntalDagar: number
    ersattningsniva: number
    dagarIAntalStudieveckor: number
    studietakt: number
}

export interface HalvarFormData {
    halvar: Kalenderhalvar
    antalVeckorHalvar: number
    studietakt: number
    lon: number
}

export const calculateKalenderhalvar = (
    formdata: HalvarFormData,
    rakneparametrar: StudieersattningParametrarApiModel,
    ageParameter: AgeInkomstBasbeloppApiModel
): ResultatKalenderhalvar => {
    const activeRakneparametrar = getActiveRakneparametrar(formdata, rakneparametrar)

    const maxErsattningCsnPerDag = calculateMaxErsattningFribeloppCsn(activeRakneparametrar)

    const maxErsattningInkomstbasbeloppPerDag = calculateMaxErsattningInkomstbasbelopp(ageParameter, activeRakneparametrar)

    const beraknadStudieersattningPerDag = calculateStudieersattningPerDag(formdata, activeRakneparametrar)

    // Det lägsta beloppet av dessa tre beräkningar
    // studieersättningsberäkningen kr/dag
    // maxersättning i förhållande till inkomstbasbeloppet
    // maxersättning i förhållande till CSN fribelopp

    const studieersattningPerDag = Math.min(maxErsattningCsnPerDag, maxErsattningInkomstbasbeloppPerDag, beraknadStudieersattningPerDag)
    const maxbeloppReached = beraknadStudieersattningPerDag > maxErsattningCsnPerDag

    log_info('ersattning', {
        maxErsattningCsnPerDag,
        maxErsattningInkomstbasbeloppPerDag,
        beraknadStudieersattningPerDag,
        maxbeloppReached,
        studieersattningPerDag,
    })
    return {
        halvar: formdata.halvar,
        antalVeckor: formdata.antalVeckorHalvar,
        ersattningsniva: activeRakneparametrar.ersattningsniva,
        csnCoversErsattningsprocent: beraknadStudieersattningPerDag <= 0,
        maxbeloppReached: maxbeloppReached,
        ersattning: {
            perDag: Math.ceil(studieersattningPerDag),
            perVecka: Math.ceil(studieersattningPerDag * dagarPerVecka),
            totalt: Math.ceil(studieersattningPerDag * activeRakneparametrar.dagarIAntalStudieveckor),
        },
    }
}

export const getActiveFribelopp = (antalDagar: number, rakneparametrar: FribeloppIntervallApiModel[]) =>
    rakneparametrar.find((l) => l.dagFran <= antalDagar && antalDagar <= l.dagTill)

export const getErsattningsProcentTermin = (halvar: number, rakneparametrarStudielangd: ErsattningProcentApiModel) => {
    switch (halvar) {
        case 1:
            return rakneparametrarStudielangd.termin1
        case 2:
            return rakneparametrarStudielangd.termin2
        case 3:
            return rakneparametrarStudielangd.termin3
        case 4:
            return rakneparametrarStudielangd.termin4
    }
}

export const getActiveRakneparametrar = (formdata: HalvarFormData, rakneparametrar: StudieersattningParametrarApiModel) => {
    const dagarIAntalStudieveckor = formdata.antalVeckorHalvar * dagarPerVecka
    const activeFribelopp = getActiveFribelopp(dagarIAntalStudieveckor, rakneparametrar.fribeloppInterval)
    const ersattningsniva = getErsattningsProcentTermin(formdata.halvar, rakneparametrar.ersattningProcent)
    const baseActiveParam: Rakneparametrar = {
        dagarPerManad: rakneparametrar?.antalDagar?.perManad,
        minAntalDagar: rakneparametrar?.antalDagar?.minPerTermin,
        studietakt: formdata.studietakt,
        ersattningsniva,
        dagarIAntalStudieveckor,
        bidrag: 0,
        fribelopp: 0,
        lan: 0,
    }

    switch (formdata.studietakt) {
        case 100:
            return {
                ...baseActiveParam,
                bidrag: rakneparametrar?.bidrag?.belopp100,
                lan: rakneparametrar?.lan?.belopp100,
                fribelopp: activeFribelopp?.fribelopp?.belopp100,
            }
        case 75:
            return {
                ...baseActiveParam,
                bidrag: rakneparametrar?.bidrag?.belopp75,
                lan: rakneparametrar?.lan?.belopp75,
                fribelopp: activeFribelopp?.fribelopp?.belopp75,
            }
        case 50:
            return {
                ...baseActiveParam,
                bidrag: rakneparametrar?.bidrag?.belopp50,
                lan: rakneparametrar?.lan?.belopp50,
                fribelopp: activeFribelopp?.fribelopp?.belopp50,
            }
    }
    return null
}
