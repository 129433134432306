import { SelectOption } from '@local/src/Components/FormComponents/Dropdown/Dropdown.types'

export interface IStudieintygFormValues {
    avserAr: SelectOption
    termin: TerminType
    sammanhangandeStudier: SammanhangandeStudierType
    isFornamnEfternamnVisible: JaNejType
    isPersonnummerIncluded: JaNejType
    isUtbildningensNamnIncluded: JaNejType
    isStudieomfattningenIncluded: JaNejType
    isDatumForTerminsstartAndTerminsslutIncluded: JaNejType
    isStudieintygetDateratTidigastBeforeTerminStartIncluded: JaNejType
    studieintygFiles: File[]
}

export type SammanhangandeStudierType = SammanhangandeStudierEnum
export enum SammanhangandeStudierEnum {
    KONTINUERLIGASTUDIER = 'KontinuerligaStudier',
    JULLEDIGHET = 'Julledighet',
    SOMMARLEDIGHET = 'Sommarledighet',
}

export type TerminType = TerminEnum
export enum TerminEnum {
    VARTERMIN = 'Vartermin',
    HOSTTERMIN = 'Hosttermin',
    LASAR = 'Lasar',
}

export type JaNejType = JaNejEnum
export enum JaNejEnum {
    JA = 'true',
    NEJ = 'false',
}

export type StudieintygTypes = StudieintygEnum
export enum StudieintygEnum {
    TERMIN = 'termin',
    AVSER_AR = 'avserAr',
    SAMMANHANGANDE_STUDIER = 'sammanhangandeStudier',
    IS_FORNAMN_EFTERNAMN_VISIBLE = 'isFornamnEfternamnVisible',
    IS_PERSONNUMMER_INCLUDED = 'isPersonnummerIncluded',
    IS_UTBILDNINGENSNAMN_INCLUDED = 'isUtbildningensNamnIncluded',
    IS_STUDIEOMFATTNINGEN_INCLUDED = 'isStudieomfattningenIncluded',
    IS_DATUM_FOR_TERMINSSTART_AND_TERMINSSLUT_INCLUDED = 'isDatumForTerminsstartAndTerminsslutIncluded',
    IS_STUDIEINTYGET_DATERAT_TIDIGAST_BEFORE_TERMIN_START_INCLUDED = 'isStudieintygetDateratTidigastBeforeTerminStartIncluded',
    STUDIEINTYG_FILES = 'studieintygFiles',
}
