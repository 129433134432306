import useCommonTranslation from '@local/src/Hooks/useCommonTranslation'
import { StudiestodAnsokanStatus } from '@local/src/Utils/Helpers/StudiestodHelper'
import { Chip } from '@mui/material'
import React from 'react'

interface Props {
    status: ValuesOf<typeof StudiestodAnsokanStatus>
}

const StatusChip = ({ status }: Props) => {
    const { tCommon } = useCommonTranslation()
    const label = tCommon('status', { context: status })

    const getStatusChipVariant = () => {
        switch (status) {
            case 'ejSkickad':
            case 'skickad':
            case 'handlaggs':
                return 'status-info'
            case 'godkand':
                return 'status-success'
            case 'ejAktuell':
                return 'status-warning'
            default:
                return 'status-info'
        }
    }

    return <Chip variant={getStatusChipVariant()} label={label} data-testid={`ansokan-card-status-chip-${status}`} />
}

export default StatusChip
