import translation from '@local/src/Content/translation.json'
import { ThemeOptions, createTheme } from '@mui/material'
import { QueryClient } from '@tanstack/react-query'
import { defaultLocale, themeOptions } from '@trr/mui-theme'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

export const reactQueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 1,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        },
    },
})

export const createTrrMuiTheme = () => {
    const customTheme: ThemeOptions = {
        components: {
            MuiCard: {
                defaultProps: {
                    sx: (theme) => ({
                        padding: theme.spacing(2),
                        [theme.breakpoints.down('md')]: {
                            padding: 0,
                        },
                        borderColor: theme.palette.neutral.divider,
                    }),
                },
            },
            MuiAccordionSummary: {
                defaultProps: {
                    sx: { userSelect: 'auto' },
                },
            },
        },
    }

    const theme = createTheme(themeOptions, defaultLocale, customTheme)

    return theme
}

export const initTranslations = () => {
    void i18n
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            resources: {
                sv: {
                    translation,
                },
            },
            ns: 'translation',
            lng: 'sv',
            fallbackLng: 'sv',
            defaultNS: 'translation',
            fallbackNS: 'translation',
        })
}
