import Loader from '@local/src/Components/Loader'
import Separator from '@local/src/Components/Separator'
import useGetStudieintygDocuments from '@local/src/Features/StudiestodStudieintyg/Hooks/useGetStudieintygDocuments'
import usePickEpiContentWithFallback from '@local/src/Hooks/usePickEpiContentWithFallback'
import { Box, styled, Typography } from '@mui/material'
import React, { Fragment } from 'react'

import StudieintygDocument from './Components/StudieintygDocument'

const StyledUl = styled('ul')(() => ({
    listStyle: 'none',
    padding: 0,
}))
interface Props {
    arendeId: string
    ansokanId: string
}
const StudieintygDocuments = ({ arendeId, ansokanId }: Props) => {
    const { studieintygDocuments, isFetching } = useGetStudieintygDocuments(arendeId, ansokanId)
    const {
        studiestodStudieintyg: { laddauppstudieintyg: content },
    } = usePickEpiContentWithFallback()
    if (studieintygDocuments && studieintygDocuments.length === 0) return <></>

    const lastDocumentIndex = studieintygDocuments.length - 1

    return (
        <Box data-testid="show-studieintyg">
            <Typography variant="h2">{content?.tidigareUppladdadeStudieintygHeader}</Typography>
            {isFetching ? (
                <Loader />
            ) : (
                <StyledUl>
                    {studieintygDocuments.map((studieintygItem, index) => (
                        <Fragment key={studieintygItem.dokumentId}>
                            <StudieintygDocument ansokanId={ansokanId} arendeId={arendeId} studieintygItem={studieintygItem} />
                            {index < lastDocumentIndex && <Separator />}
                        </Fragment>
                    ))}
                </StyledUl>
            )}
        </Box>
    )
}

export default StudieintygDocuments
