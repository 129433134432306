import {
    HalvarFormData,
    Rakneparametrar,
} from '@local/src/Features/StudieersattningRaknesnurraForm/Helpers/raknesnurraCalculatorKalenderhalvar'
import { AgeInkomstBasbeloppApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { log_info } from '@local/src/Utils/Helpers/logger'

const convertPercentToDecimal = (percent: number) => percent / 100

const calculateAmountPerDay = (amount: number, totalDays: number) => amount / totalDays

const studieersattningPerDayFormula = (lon: number, antalDagarPerManad: number, activeRakneparametrar: Rakneparametrar) => {
    const { studietakt, ersattningsniva, lan, bidrag } = activeRakneparametrar
    const lonPerDag = calculateAmountPerDay(lon, antalDagarPerManad)
    const studietaktAsDecimal = convertPercentToDecimal(studietakt)
    const ersattningsnivaAsDecimal = convertPercentToDecimal(ersattningsniva)

    // Formel: för att räkna ut studieersättning per dag
    // ( (lön / antal dagar i månaden) * aktuell ersättningsnivå) - Lånedel – Bidragsdel ) * studietakt
    return (lonPerDag * ersattningsnivaAsDecimal - lan - bidrag) * studietaktAsDecimal
}

export const calculateMaxErsattningFribeloppCsn = (activeRakneparametrar: Rakneparametrar) => {
    const { fribelopp, studietakt, minAntalDagar, dagarIAntalStudieveckor } = activeRakneparametrar
    // minAntalDagar = 80
    // antalet dagar ska vara minst 80 eller högre
    const antalDagar = Math.max(minAntalDagar, dagarIAntalStudieveckor)
    const fribeloppPerDag = calculateAmountPerDay(fribelopp, antalDagar)
    const studietaktAsDecimal = convertPercentToDecimal(studietakt)
    log_info('MaxErsattningFribeloppCsn', { fribeloppPerDag, studietaktAsDecimal, minAntalDagar, dagarIAntalStudieveckor, antalDagar })

    // Formel för att få fram maxbelopp per dag i förhållande till CSN fribelopp
    // (Fribelopp / Antal dagar i terminen) * Studietakt
    return fribeloppPerDag * studietaktAsDecimal
}

export const calculateMaxErsattningInkomstbasbelopp = (ageParameter: AgeInkomstBasbeloppApiModel, rakneparametrar: Rakneparametrar) => {
    // Formel för att få fram maxbelopp per dag i förhållande till inkomstbasbeloppet
    // beräkning av lön för inkomstbasbelopp: ageIbbFaktor x inkomstbasbelopp

    const { ageDagarPerManad, ageIbbFaktor, inkomstBasbelopp } = ageParameter
    const lonInkomstbasbelopp = ageIbbFaktor * inkomstBasbelopp

    log_info('MaxErsattningInkomstbasbelopp', { ageDagarPerManad, ageIbbFaktor, inkomstBasbelopp, lonInkomstbasbelopp })
    // sedan beräkna studieersättning per dag med samma formel: för att räkna ut studieersättning per dag
    return studieersattningPerDayFormula(lonInkomstbasbelopp, ageDagarPerManad, rakneparametrar)
}

export const calculateStudieersattningPerDag = (formdata: HalvarFormData, rakneparametrar: Rakneparametrar) => {
    log_info('StudieersattningPerDag', { lon: formdata.lon, dagarPerManad: rakneparametrar.dagarPerManad })
    return studieersattningPerDayFormula(formdata.lon, rakneparametrar.dagarPerManad, rakneparametrar)
}
