import { yupResolver } from '@hookform/resolvers/yup'
import { useScrollToError } from '@local/src/Hooks'
import { useForm } from 'react-hook-form'

import { RaknesnurraFormData } from '../Models/RaknesnurraForm.types'
import { RaknesnurraEpiContent } from '../StudieersattningRaknesnurraForm.content'
import { RaknesnurraFormSchema } from '../Schemas/RaknesnurraFormSchema'

export const useRaknesnurraForm = (epiContent: RaknesnurraEpiContent) => {
    const methods = useForm<RaknesnurraFormData>({
        defaultValues: {
            fodelsedatum: null,
            loneniva: null,
            studietakt: null,
            beraknadStudiestart: null,
            antalKalenderhalvar: 1,
            veckorKalenderHalvar1: null,
            veckorKalenderHalvar2: null,
            veckorKalenderHalvar3: null,
            veckorKalenderHalvar4: null,
        },
        resolver: yupResolver(RaknesnurraFormSchema(epiContent)),
        mode: 'onSubmit',
        shouldFocusError: true,
    })

    const {
        formState: { errors, isSubmitted },
    } = methods

    useScrollToError(errors, isSubmitted)

    return methods
}
