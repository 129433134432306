import { UtbildningsEnum } from '@local/src/Features/StudiestodAnsokanForm/Components/Utbildningar/UtbildningForm/Models/Utbildningsform.types'
import { StudiestodAnsokanState, StudiestodApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { isNil } from 'ramda'

export const StudiestodAnsokanStatus = {
    EjSkickad: 'ejSkickad',
    Skickad: 'skickad',
    Handlaggs: 'handlaggs',
    Godkand: 'godkand',
    EjAktuell: 'ejAktuell',
} as const

export const mapStudiestodAnsokanStateToStatus = (state: StudiestodAnsokanState) => {
    switch (state) {
        case StudiestodAnsokanState.Sparad:
            return StudiestodAnsokanStatus.EjSkickad
        case StudiestodAnsokanState.Publicerad:
            return StudiestodAnsokanStatus.Skickad
        case StudiestodAnsokanState.Handlaggs:
        case StudiestodAnsokanState.Last:
        case StudiestodAnsokanState.UnderlagSparad:
        case StudiestodAnsokanState.UnderlagPublicerad:
            return StudiestodAnsokanStatus.Handlaggs
        case StudiestodAnsokanState.Beslutad:
            return StudiestodAnsokanStatus.Godkand
        case StudiestodAnsokanState.EjAktuell:
            return StudiestodAnsokanStatus.EjAktuell
        default:
            return StudiestodAnsokanStatus.EjSkickad
    }
}

export const hasUtbildningstyp = (ansokan: StudiestodApiModel, typ: UtbildningsEnum) =>
    !isNil(ansokan?.utbildningar.find((u) => u.utbildningstyp === typ.toString()))

export const toUrl = (baseUrl: string, arendeId: string, ansokanId: string) => `${baseUrl}/${arendeId}/${ansokanId}`.replace('//', '/')
