import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown } from '@local/src/Components/FormComponents'

interface Props {
    isFetching: boolean
}
const StudietaktDropdown = ({ isFetching }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'kompletterandeStudiestodRaknesnurraForm' })

    const studietaktSelectOptions = t('studietaktSelectOptions', { returnObjects: true })
    return (
        <Dropdown
            testId="studietakt"
            isReadOnly={isFetching}
            label={t('selectLabel')}
            name="studietakt"
            selectOptions={studietaktSelectOptions}
        />
    )
}

export default StudietaktDropdown
