import { getBaseRequestParams, studiestodApi } from '@local/src/Services/apiEndpointHelper'

export const downloadDocumentById = async (arendeId: string, ansokanId: string, documentId: string, fileName: string) => {
    const res = await studiestodApi.arendeAnsokanDocumentDetail(arendeId, ansokanId, documentId, getBaseRequestParams())
    const blob = await res.blob()
    const anchor = document.createElement('a')
    document.body.appendChild(anchor)
    const objectUrl = window.URL.createObjectURL(blob)
    anchor.href = objectUrl
    anchor.download = fileName
    anchor.target = '_blank'
    anchor.setAttribute('data-trackable', 'false') //disable gtm tracking
    anchor.click()
}
