export const removeDuplicateSlashes = (url: string) => url.replace(/([^:]\/)\/+/g, '$1')

export const isValidUrl = (string: string) => {
	try {
		new URL(string)
		return true
	} catch (err) {
		return false
	}
}
