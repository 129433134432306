import { raknesnurrorApi } from '@local/src/Services/apiEndpointHelper'
import { apiRequestTryCatchWrapper } from '@local/src/Services/apiRequestHelper'
import { useQuery } from '@tanstack/react-query'

const useBeraknaKompletterandeStudiestod = (salary: number, shouldFetch = false) => {
    const queryKey = ['beraknaKompletterandeStudiestod', salary]

    const response = useQuery({
        queryKey,
        queryFn: () =>
            apiRequestTryCatchWrapper('beraknaKompletterandeStudiestod', raknesnurrorApi.raknesnurrorEkonomisktStudiestodCreate, {
                salary,
            }),
        enabled: shouldFetch && Boolean(salary),
        keepPreviousData: true,
        staleTime: 1000,
        cacheTime: 0,
    })
    return response
}

export default useBeraknaKompletterandeStudiestod
