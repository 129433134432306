import useSignalRJoinGroup from '@local/src/Hooks/SignalR/useSignalRJoinGroup'
import useSignalROnTargetMethod from '@local/src/Hooks/SignalR/useSignalROnTargetMethod'
import useCurrentUser from '@local/src/Hooks/useCurrentUser'
import { ReactQueryKey } from '@local/src/Services/apiEndpointHelper'
import { HubConnection } from '@microsoft/signalr'
import { useQueryClient } from '@tanstack/react-query'

const useStudiestodSignalR = (hub: HubConnection) => {
    const { getUserMdId } = useCurrentUser()

    const queryClient = useQueryClient()

    useSignalRJoinGroup({
        hub: hub,
        groupName: `StudiestodEvent-${getUserMdId()}`,
    })

    useSignalROnTargetMethod({
        hub: hub,
        targetMethod: 'StudiestodNummerIsSetEvent',
        callBack: async () => {
            await queryClient.invalidateQueries([ReactQueryKey.getStudiestodList, getUserMdId()])
        },
    })
}

export default useStudiestodSignalR
