import usePickEpiContentWithFallback from '@local/src/Hooks/usePickEpiContentWithFallback'
import { Box, Link, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const NoResult = () => {
    const { studiestodList: content } = usePickEpiContentWithFallback()
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokanList' })

    return (
        <Box
            sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: theme.palette.grey[100],
                width: '100%',
                padding: 2,
                borderRadius: 2,
            })}
        >
            <Typography data-testid="no-result-information">
                {t('noResult', { context: 'aktuella' })} <Link href={content.mittTrrReadMoreLink}>{t('readMore')}</Link>
            </Typography>
        </Box>
    )
}

export default NoResult
