import { StudieersattningParametrarApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { log_error } from '@local/src/Utils/Helpers/logger'
import { intervalToDuration, parse } from 'date-fns'
import { sv } from 'date-fns/locale'

import { RaknesnurraFormData, RaknesnurraFormResultData } from '../Models/RaknesnurraForm.types'

import { HalvarFormData, Kalenderhalvar, calculateKalenderhalvar } from './raknesnurraCalculatorKalenderhalvar'

export const getAgeInYears = (fodelsedatum: Date, datum: Date) => {
    const interval = {
        start: fodelsedatum,
        end: datum,
    }
    const { years } = intervalToDuration(interval)
    return years
}

export const calculateRaknesnurraResult = (formData: RaknesnurraFormData, rakneparametrar: StudieersattningParametrarApiModel[]) => {
    try {
        const { beraknadStudiestart, fodelsedatum } = formData

        const beraknadStudiestartLastDayOfMonth = new Date(beraknadStudiestart.getFullYear(), beraknadStudiestart.getMonth() + 1, 0)
        const fodelseDatumAsDate = parse(fodelsedatum.toString(), 'yyyyMMdd', new Date(), { locale: sv })

        const ageAtStudyStart = getAgeInYears(fodelseDatumAsDate, beraknadStudiestart)
        const ageLastDayOfMonthAtStudyStart = getAgeInYears(fodelseDatumAsDate, beraknadStudiestartLastDayOfMonth)
        const isYoungerThan40 = ageAtStudyStart < 40
        const is65OrOlder = ageLastDayOfMonthAtStudyStart >= 65
        const isInvalid = is65OrOlder || isYoungerThan40

        const result: RaknesnurraFormResultData = {
            isYoungerThan40: isYoungerThan40,
            is65OrOlder: is65OrOlder,
            isValid: !isInvalid,
            isInvalid: isInvalid,
            kalenderhalvar: [],
            isUnhandledError: false,
        }

        if (isInvalid) return result

        const activeRakneparameter = rakneparametrar.find((l) => {
            const giltigFran = new Date(l.giltigFran)
            const giltigTill = new Date(l.giltigTill)
            return giltigFran <= beraknadStudiestart && beraknadStudiestart <= giltigTill
        })

        const activeAgeInkomstbasbelopp = activeRakneparameter.ageInkomstBasbelopp.find((l) => {
            const giltigFran = new Date(l.giltigFran)
            const giltigTill = new Date(l.giltigTill)
            return giltigFran <= beraknadStudiestart && beraknadStudiestart <= giltigTill
        })

        result.kalenderhalvar.push(
            calculateKalenderhalvar(getFormDataForHalvar(1, formData), activeRakneparameter, activeAgeInkomstbasbelopp)
        )
        if (formData.veckorKalenderHalvar2) {
            result.kalenderhalvar.push(
                calculateKalenderhalvar(getFormDataForHalvar(2, formData), activeRakneparameter, activeAgeInkomstbasbelopp)
            )
        }
        if (formData.veckorKalenderHalvar3) {
            result.kalenderhalvar.push(
                calculateKalenderhalvar(getFormDataForHalvar(3, formData), activeRakneparameter, activeAgeInkomstbasbelopp)
            )
        }
        if (formData.veckorKalenderHalvar4) {
            result.kalenderhalvar.push(
                calculateKalenderhalvar(getFormDataForHalvar(4, formData), activeRakneparameter, activeAgeInkomstbasbelopp)
            )
        }
        return result
    } catch (error) {
        log_error('calculateRaknesnurraResult', { error, formData, rakneparametrar })
        return {
            isYoungerThan40: false,
            is65OrOlder: false,
            isValid: false,
            isInvalid: true,
            kalenderhalvar: [],
            isUnhandledError: true,
        }
    }
}

const getFormDataForHalvar = (halvar: Kalenderhalvar, formData: RaknesnurraFormData): HalvarFormData => {
    let antalVeckorHalvar = 0
    switch (halvar) {
        case 1:
            antalVeckorHalvar = formData.veckorKalenderHalvar1
            break
        case 2:
            antalVeckorHalvar = formData.veckorKalenderHalvar2
            break
        case 3:
            antalVeckorHalvar = formData.veckorKalenderHalvar3
            break
        case 4:
            antalVeckorHalvar = formData.veckorKalenderHalvar4
            break
    }
    return {
        halvar,
        antalVeckorHalvar,
        studietakt: formData.studietakt,
        lon: formData.loneniva,
    }
}
