import {
    studieintygFileSizeLimitInKb,
    studieintygValidFileFormats,
} from '@local/src/Features/StudiestodStudieintyg/Hooks/useStudieintygFilePicker'
import { FileListError, FileValidation } from '@local/src/Hooks/useFilePicker'
import usePickEpiContentWithFallback from '@local/src/Hooks/usePickEpiContentWithFallback'
import { Box, Typography, styled } from '@mui/material'
import React from 'react'

const StyledUl = styled('ul')(() => ({
    listStyle: 'none',
    paddingLeft: 0,
}))
interface Props {
    fileListError: FileListError
    hasError: boolean
}

const FileUploadError = ({ fileListError, hasError }: Props) => {
    const {
        studiestodStudieintyg: {
            laddauppstudieintyg: { fileUploadHasErrorText, fileUploadHasErrorsText },
            felmeddelanden: { fileNameErrorMessage, fileFormatErrorMessage, fileSizeErrorMessage },
        },
    } = usePickEpiContentWithFallback()

    if (!hasError) return <></>

    const getErrorText = (value: FileValidation) => {
        switch (value.validation.type) {
            case 'size':
                return `${fileSizeErrorMessage} ${studieintygFileSizeLimitInKb}Kb`
            case 'extension':
                return `${fileFormatErrorMessage} ${studieintygValidFileFormats.join(', ')}.`

            case 'custom':
                return fileNameErrorMessage
        }
    }

    const hasMultipleFileErrors = fileListError.filesValidation?.length > 1

    return (
        <Box
            mb={3}
            sx={(theme) => ({
                color: theme.palette.error.main,
            })}
            data-testid="fileupload-error"
        >
            <Typography>{hasMultipleFileErrors ? fileUploadHasErrorsText : fileUploadHasErrorText}</Typography>
            <StyledUl>
                {fileListError.filesValidation?.map((value, key) => <li key={key}>{`${value.file?.name} ${getErrorText(value)} `}</li>)}
            </StyledUl>
        </Box>
    )
}

export default FileUploadError
