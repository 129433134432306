import Icons from '@local/src/Components/Icons'
import usePickEpiContentWithFallback from '@local/src/Hooks/usePickEpiContentWithFallback'
import { StudiestodApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import getConfig from '@local/Utils/getConfig'
import { Alert, Box, Collapse, Link, Typography } from '@mui/material'
import React, { Fragment, useState } from 'react'

interface Props {
    ansokan: StudiestodApiModel
}

const WarningAlert = ({ children }: React.PropsWithChildren) => {
    const [isOpen, setIsOpen] = useState(true)

    return (
        <Collapse in={isOpen}>
            <Alert icon={<Icons.Warning />} severity="warning" sx={{ mb: 3 }} onClose={() => setIsOpen(false)}>
                {children}
            </Alert>
        </Collapse>
    )
}
const DescriptionStudieintyg = ({ ansokan }: Props) => {
    const {
        studiestodStudieintyg: { laddauppstudieintyg: content, studieintygMall, infoCsnOmstallningsstod },
    } = usePickEpiContentWithFallback()

    const ansokanNamn = ansokan?.studiestodNummer ? `#${ansokan?.studiestodNummer} - ${ansokan?.ansokanNamn}` : ansokan?.ansokanNamn

    return (
        <Fragment>
            <WarningAlert>
                <Typography>
                    {content.description4}&nbsp;
                    <Link underline="hover" rel="noopener" href={infoCsnOmstallningsstod.readMoreLink}>
                        {infoCsnOmstallningsstod.readMoreLinkText}
                    </Link>
                </Typography>
            </WarningAlert>

            <Typography mb={2}>{content?.description1}</Typography>

            <Box>
                <Typography
                    variant="h6"
                    sx={(theme) => ({
                        borderRadius: 1,
                        border: `1px solid ${theme.palette.grey[600]}`,
                        pt: 2,
                        pb: 2,
                        pl: 3,
                        mb: 2,
                        wordWrap: 'break-word',
                    })}
                >
                    {ansokanNamn}
                </Typography>
                <Typography mb={2}>{content?.description2}</Typography>
                <Typography mb={2}>{content?.description3}</Typography>

                <Link
                    target="_blank"
                    component="a"
                    underline="hover"
                    rel="noopener noreferrer"
                    href={`${getConfig().MEDIA_URL}${studieintygMall.studieintygMallLink}`}
                    flexDirection="row"
                    display="inline-flex"
                >
                    <Icons.Download />
                    <Typography ml={1} fontWeight={(theme) => theme.typography.fontWeightMedium}>
                        {studieintygMall.studieintygMallLinkText}
                    </Typography>
                </Link>
            </Box>
        </Fragment>
    )
}

export default DescriptionStudieintyg
