import { JaNejRadioButtonGroup, TextArea } from '@local/src/Components/FormComponents'
import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'

import { StudiestodAnsokanFormData } from '../../../../../Models/StudiestodAnsokanForm.types'

import { CsnInformationFormEpiContent } from './CsnInformationForm.content'

interface Props {
    content: CsnInformationFormEpiContent
    index: number
}
const CsnInformationForm = ({ content, index }: Props) => {
    const { watch } = useFormContext<StudiestodAnsokanFormData>()
    const { utbildningar, isReadonly } = watch()
    const currentUtbildning = utbildningar[index]
    const { avserSokaCsn } = currentUtbildning
    return (
        <Stack spacing={2}>
            <Stack spacing={1}>
                <Typography variant="h6">{content.csnInformationHeaderText}</Typography>
                <Typography>{content.csnInformationLeadText}</Typography>
            </Stack>
            <JaNejRadioButtonGroup
                label={content.studiemedelsberattigadUtbildningText}
                name={`utbildningar[${index}].studiemedelsberattigadUtbildning`}
                isReadonly={isReadonly}
            />
            <JaNejRadioButtonGroup label={content.avserSokaCsnText} name={`utbildningar[${index}].avserSokaCsn`} isReadonly={isReadonly} />

            {!avserSokaCsn && (
                <TextArea
                    name={`utbildningar[${index}].ejCsnAnledning`}
                    placeholder={content.ejCsnAnledningPlaceholderText}
                    label={content.ejCsnAnledningText}
                    disabled={isReadonly}
                />
            )}
            <JaNejRadioButtonGroup
                label={content.kraverProvningText}
                name={`utbildningar[${index}].kraverProvning`}
                isReadonly={isReadonly}
            />
        </Stack>
    )
}

export default CsnInformationForm
