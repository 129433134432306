import { studiestodApi } from '@local/src/Services/apiEndpointHelper'
import { apiGetTryCatchWrapper } from '@local/src/Services/apiRequestHelper'
import { useQuery } from '@tanstack/react-query'

const useGetStudieintygDocuments = (arendeId: string, ansokanId: string) => {
    const { data: studieintygDocuments, ...query } = useQuery({
        queryFn: () => apiGetTryCatchWrapper('useGetStudieintygDocuments', studiestodApi.arendeAnsokanDocumentsDetail, arendeId, ansokanId),
        queryKey: ['getStudieintyg', arendeId, ansokanId],
        placeholderData: [],
    })

    return { studieintygDocuments, ...query }
}
export default useGetStudieintygDocuments
