/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'

const Form = ({ ...props }: React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>) => {
    const handleOnKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
        const isTextArea = (event.target as HTMLFormElement).tagName === 'TEXTAREA'
        if (isTextArea) return
        if (event.key === 'Enter') {
            event.preventDefault()
        }
    }

    return <form {...props} onKeyDown={handleOnKeyDown} />
}

export default Form
