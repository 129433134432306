import { RangeDropdown } from '@local/src/Components/FormComponents/Dropdown'
import { RadioButton, RadioButtonGroup } from '@local/src/Components/FormComponents/RadioButtonGroup/RadioButtonGroup'
import { LaddaUppStudieintygEpiContent } from '@local/src/Features/StudiestodStudieintyg/StudiestodStudieintyg.content'
import { Box } from '@mui/material'
import React from 'react'

import { SammanhangandeStudierEnum, TerminEnum } from '../UploadStudieintyg/Models/StudieintygTypes'

const currentYear = new Date().getFullYear()

interface Props {
    content: LaddaUppStudieintygEpiContent
}

export const Termin = ({ content }: Props) => {
    const radios: RadioButton[] = [
        {
            label: content?.infoOmStudieintygRadioButtonVartermin,
            value: TerminEnum.VARTERMIN,
        },
        {
            label: content?.infoOmStudieintygRadioButtonHosttermin,
            value: TerminEnum.HOSTTERMIN,
        },
        {
            label: content?.infoOmStudieintygRadioButtonLasar,
            value: TerminEnum.LASAR,
        },
    ]

    return <RadioButtonGroup name="termin" radios={radios} label="" />
}

export const SammanhangandeStudier = ({ content }: Props) => {
    const radios: RadioButton[] = [
        {
            label: content?.infoOmStudieintygRadioButtonKontinuerligaStudier,
            value: SammanhangandeStudierEnum.KONTINUERLIGASTUDIER,
        },
        {
            label: content?.infoOmStudieintygRadioButtonJulledight,
            value: SammanhangandeStudierEnum.JULLEDIGHET,
        },
        {
            label: content?.infoOmStudieintygRadioButtonSommarledighet,
            value: SammanhangandeStudierEnum.SOMMARLEDIGHET,
        },
    ]
    return (
        <RadioButtonGroup
            name="sammanhangandeStudier"
            radios={radios}
            label={content?.infoOmStudieintygRadioButtonSammanhangandeStudierHeader}
        />
    )
}

export const AvserAr = ({ content }: Props) => (
    <Box width="281px" mb={2}>
        <RangeDropdown
            rangeFrom={currentYear - 4}
            rangeLength={5}
            label={content?.infoOmStudieintygDropdownLabel}
            reversed
            name="avserAr"
        />
    </Box>
)
