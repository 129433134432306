import React from 'react'

interface KeyRouteProps {
    children?: React.ReactNode
    urlKey?: string
    isMatch?: () => boolean
}
export const KeyRoute = ({ children }: KeyRouteProps) => <>{children}</>
interface AppShellRouterProps {
    currentKey: string
}
export const AppShellRouter = ({ currentKey, children }: React.PropsWithChildren<AppShellRouterProps>) => {
    let component

    React.Children.forEach(children, (child: React.ReactElement<KeyRouteProps>) => {
        if (React.isValidElement(child)) {
            if (child.props.isMatch?.()) {
                component = child
            } else if (child.props.urlKey === currentKey) {
                component = child
            }
        } else {
            component = null
        }
    })

    return component ?? null
}
