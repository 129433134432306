import { ReactQueryKey, studiestodApi } from '@local/src/Services/apiEndpointHelper'
import { apiGetTryCatchWrapper } from '@local/src/Services/apiRequestHelper'
import { useQuery, useQueryClient } from '@tanstack/react-query'

const useGetCurrentStudiestodansokan = (arendeId: string, ansokanId: string) => {
    const queryClient = useQueryClient()
    const queryKey = [ReactQueryKey.getStudiestodsansokanById, arendeId, ansokanId]

    const result = useQuery({
        queryKey: queryKey,
        queryFn: () => apiGetTryCatchWrapper('useGetCurrentStudiestodansokan', studiestodApi.arendeAnsokanDetail, arendeId, ansokanId),
    })

    const invalidateQuery = async () => await queryClient.invalidateQueries(queryKey)

    const isEjAktuell = result?.data?.studiestodAnsokanState === 8
    return { ...result, invalidateQuery, isAktuell: !isEjAktuell, isEjAktuell }
}

export default useGetCurrentStudiestodansokan
