export const getLocalStorageState = <Type>(key: string): Type => {
  try {
    const serializedState = localStorage.getItem(key)
    if (serializedState === null) {
      return undefined
    }

    return JSON.parse(serializedState) as Type
  } catch (err) {
    return undefined
  }
}

export const setLocalStorageState = <T>(key: string, value: T) => {
  try {
    const serializedState = JSON.stringify(value)
    localStorage.setItem(key, serializedState)
  } catch {
    //Ignore
  }
}
export const removeLocalStorageState = (key: string) => {
  try {
    localStorage.removeItem(key)
  } catch {
    //Ignore
  }
}
export const getSessionStorageState = <Type>(key: string): Type => {
  try {
    const serializedState = sessionStorage.getItem(key)
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState) as Type
  } catch (err) {
    return undefined
  }
}

export const setSessionStorageState = <T>(key: string, value: T) => {
  try {
    const serializedState = JSON.stringify(value)
    sessionStorage.setItem(key, serializedState)
  } catch {
    //Ignore
  }
}
