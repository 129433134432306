import { UtbildningsEnum } from './Utbildningsform.types'

export const omfattningLabels = [
  { label: '100%', value: 100 },
  { label: '75%', value: 75 },
  { label: '50%', value: 50 },
  { label: '25%', value: 25 },
]

export const utbildningstypLabels = [
  {
    label: 'Universitet/högskola',
    value: UtbildningsEnum.UNIVERSITET_HOGSKOLA,
  },
  { label: 'Yrkeshögskola', value: UtbildningsEnum.YRKESHOGSKOLA },
  {
    label: 'Arbetsmarknadsutbildning',
    value: UtbildningsEnum.ARBETSMARKNADSUTBILDNING,
  },
  { label: 'Gymnasieutbildning', value: UtbildningsEnum.GYMNASIEUTBILDNING },
  { label: 'Annat', value: UtbildningsEnum.ANNAT },
]

export const utbildningLangdEnhetLabels = [
  { label: 'Terminer', value: 0 },
  { label: 'Veckor', value: 1 },
  { label: 'Dagar', value: 2 },
]
