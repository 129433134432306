/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    CreateAnsokanApiModel,
    KlientAnsokanCreateParams,
    KlientAnsokanDeletePayload,
    KlientAnsokanDetailParams,
    KlientAnsokanStudieintygPartialUpdatePayload,
    KlientAnsokanUpdateParams,
    StudiestodApiModel,
    StudiestodListApiModel,
    TrrProblemDetails,
    UpdateAnsokanApiModel,
} from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class StudiestodKlient<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags StudiestodKlient
     * @name KlientAnsokanCreate
     * @request POST:/studiestod/klient/{klientId}/ansokan
     * @secure
     * @response `201` `StudiestodApiModel` Created
     * @response `500` `TrrProblemDetails` Internal Server Error
     */
    klientAnsokanCreate = ({ klientId, ...query }: KlientAnsokanCreateParams, data: CreateAnsokanApiModel, params: RequestParams = {}) =>
        this.request<StudiestodApiModel, TrrProblemDetails>({
            path: `/studiestod/klient/${klientId}/ansokan`,
            method: 'POST',
            query: query,
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags StudiestodKlient
     * @name KlientAnsokanDetail
     * @request GET:/studiestod/klient/{klientId}/ansokan
     * @secure
     * @response `200` `(StudiestodListApiModel)[]` OK
     * @response `500` `TrrProblemDetails` Internal Server Error
     */
    klientAnsokanDetail = ({ klientId, ...query }: KlientAnsokanDetailParams, params: RequestParams = {}) =>
        this.request<StudiestodListApiModel[], TrrProblemDetails>({
            path: `/studiestod/klient/${klientId}/ansokan`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags StudiestodKlient
     * @name KlientAnsokanUpdate
     * @request PUT:/studiestod/klient/{klientId}/ansokan/{id}
     * @secure
     * @response `204` `void` No Content
     * @response `500` `TrrProblemDetails` Internal Server Error
     */
    klientAnsokanUpdate = (
        { klientId, id, ...query }: KlientAnsokanUpdateParams,
        data: UpdateAnsokanApiModel,
        params: RequestParams = {}
    ) =>
        this.request<void, TrrProblemDetails>({
            path: `/studiestod/klient/${klientId}/ansokan/${id}`,
            method: 'PUT',
            query: query,
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        })
    /**
     * No description
     *
     * @tags StudiestodKlient
     * @name KlientAnsokanDelete
     * @request DELETE:/studiestod/klient/{klientId}/ansokan/{id}
     * @secure
     * @response `204` `void` No Content
     * @response `500` `TrrProblemDetails` Internal Server Error
     */
    klientAnsokanDelete = (klientId: string, id: string, data: KlientAnsokanDeletePayload, params: RequestParams = {}) =>
        this.request<void, TrrProblemDetails>({
            path: `/studiestod/klient/${klientId}/ansokan/${id}`,
            method: 'DELETE',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params,
        })
    /**
     * No description
     *
     * @tags StudiestodKlient
     * @name KlientAnsokanStudieintygPartialUpdate
     * @request PATCH:/studiestod/klient/{klientId}/ansokan/{id}/studieintyg
     * @secure
     * @response `204` `void` No Content
     * @response `500` `TrrProblemDetails` Internal Server Error
     */
    klientAnsokanStudieintygPartialUpdate = (
        klientId: string,
        id: string,
        data: KlientAnsokanStudieintygPartialUpdatePayload,
        params: RequestParams = {}
    ) =>
        this.request<void, TrrProblemDetails>({
            path: `/studiestod/klient/${klientId}/ansokan/${id}/studieintyg`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.FormData,
            ...params,
        })
}
