import SubmitResponse from '@local/src/Components/SubmitResponse/SubmitResponse'
import { useDeleteStudiestodAnsokan, usePickEpiContentWithFallback, useUrlQuery } from '@local/src/Hooks'
import useCommonTranslation from '@local/src/Hooks/useCommonTranslation'
import { StudiestodAnsokanStatus, toUrl } from '@local/src/Utils/Helpers/StudiestodHelper'
import { Button, Stack } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useCurrentUser from '@local/src/Hooks/useCurrentUser'
import { useIsFeatureEnabled } from '@trr/app-shell-data'

import AnsokanItemCardDeleteDialog from './AnsokanItemCardDeleteDialog'

interface Props {
    status: ValuesOf<typeof StudiestodAnsokanStatus>
    hasStudieintyg: boolean
    isRedigerbar: boolean
    arendeId: string
    ansokanId: string
    isDeletable: boolean
    canUploadStudieintyg: boolean
}

const AnsokanItemCardActions = ({
    status,
    hasStudieintyg,
    isRedigerbar,
    arendeId,
    ansokanId,
    isDeletable,
    canUploadStudieintyg,
}: Props) => {
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const { studiestodList, FunctionPage } = usePickEpiContentWithFallback()
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokanList.ansokanItemCardActions' })
    const { tCommon } = useCommonTranslation()
    const urlQuery = useUrlQuery()
    const { getUserMdId } = useCurrentUser()
    const { apiResponse, deleteStudiestodAnsokan } = useDeleteStudiestodAnsokan(getUserMdId())

    const { mittTrrStudieintygLink, mittTrrAnsokanLink } = studiestodList
    const isNewStudieintygLogicEnabled = useIsFeatureEnabled('Studiestod-Frontend_new-studieintyg-knapp-condition_temp_241109')

    const showStudieintygLink = () => (isNewStudieintygLogicEnabled ? newLogicShowStudieintygLink() : oldLogicShowStudieintygLink())
    const newLogicShowStudieintygLink = () => canUploadStudieintyg || urlQuery.get('showStudieintyg') === 'true'
    const oldLogicShowStudieintygLink = () => {
        if (status === StudiestodAnsokanStatus.EjSkickad || status === StudiestodAnsokanStatus.EjAktuell) {
            return false
        }

        const hasAccessToStudieintygPage = FunctionPage?.name === 'Studieintyg'

        return hasAccessToStudieintygPage || hasStudieintyg || urlQuery.get('showStudieintyg') === 'true'
    }

    const handleOpenDialog = () => setOpenDialog(true)
    const handleCloseDialog = () => setOpenDialog(false)

    const handleConfirmDialog = async (orsak: string) => {
        await deleteStudiestodAnsokan.mutateAsync({ ansokanId, orsak })
        setOpenDialog(false)
    }

    const isAktuell = status !== 'ejAktuell'
    const translationKey = isRedigerbar && isAktuell ? 'button_edit' : 'button_show'
    const ansokanLinkButton = t(translationKey)

    return (
        <Stack spacing={2}>
            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between">
                <Stack direction={{ xs: 'column', sm: 'row' }}>
                    {showStudieintygLink() && (
                        <Button variant="text" href={toUrl(mittTrrStudieintygLink, arendeId, ansokanId)} data-testid="studieintyg-link">
                            {t('button_studieintyg')}
                        </Button>
                    )}
                    <Button
                        variant="text"
                        href={toUrl(mittTrrAnsokanLink, arendeId, ansokanId)}
                        data-testid={`studiestodansokan-link-${translationKey}`}
                    >
                        {ansokanLinkButton}
                    </Button>
                </Stack>
                {isDeletable && (
                    <Button onClick={handleOpenDialog} variant="text" data-testid="ansokan-item-delete-button">
                        {tCommon('button_delete')}
                    </Button>
                )}
            </Stack>
            <AnsokanItemCardDeleteDialog
                open={openDialog}
                onCancel={handleCloseDialog}
                onConfirm={handleConfirmDialog}
                isPostingDelete={deleteStudiestodAnsokan.isLoading}
            />
            <SubmitResponse
                apiResponse={apiResponse}
                successMessage={t('deletedSuccessMessage')}
                failureMessage={t('deletedFailedMessage')}
            />
        </Stack>
    )
}

export default AnsokanItemCardActions
