import Form from '@local/src/Components/Form'
import { PageWrapper } from '@local/src/Components/PageWrapper'
import SubmitResponse from '@local/src/Components/SubmitResponse/SubmitResponse'
import { useGetCurrentStudiestodansokan, usePickEpiContentWithFallback } from '@local/src/Hooks'
import { useQueryClient } from '@tanstack/react-query'
import { useDirtyCheckPrompt } from '@trr/react-use-dirty-check-prompt'
import React, { useEffect, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import useGTMTracking from '@local/src/Hooks/useGTMTracking'
import { useTranslation } from 'react-i18next'
import useCurrentUser from '@local/src/Hooks/useCurrentUser'

import StudieintygDocuments from './Components/StudieintygDocuments/StudieintygDocuments'
import StudieintygForm from './Components/StudieintygForm/StudieintygForm'
import { useStudieintygForm } from './Hooks/useStudieintygForm'
import { StudieintygFormModel } from './Models/StudieintygFormModel'
import { uploadStudieintyg } from './Services/UploadStudieintyg'

interface Props {
    arendeId: string
    ansokanId: string
}
const StudiestodStudieintyg = ({ arendeId, ansokanId }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'gtmTracking.studieintyg' })
    const [apiResponse, setApiResponse] = useState(undefined)
    const { data, isFetching, isError } = useGetCurrentStudiestodansokan(arendeId, ansokanId)
    const queryClient = useQueryClient()

    const {
        studiestodStudieintyg: { laddauppstudieintyg: content },
    } = usePickEpiContentWithFallback()
    const { getUserMdId, getUserIdToken } = useCurrentUser()
    const id_token = getUserIdToken()
    const klientId = getUserMdId()

    const formMethods = useStudieintygForm(data, content)

    const {
        handleSubmit,
        reset,
        formState: { isDirty, isSubmitSuccessful, dirtyFields },
    } = formMethods

    const isReallyDraft = isDirty && Object.keys(dirtyFields).length > 0
    const { routerPrompt: RenderUnsavedChangesPrompt } = useDirtyCheckPrompt(isReallyDraft, content.lamnaIfylltFormularWarningText)

    useEffect(() => {
        if (!isSubmitSuccessful) return
        reset()
    }, [isSubmitSuccessful, reset])

    const { trackClick } = useGTMTracking()

    const onSubmit = async (formData: StudieintygFormModel) => {
        const fData = new FormData()
        fData.append('AvserAr', formData.avserAr)
        fData.append('Termin', formData.termin)
        formData.sammanhangandeStudier && fData.append('SammanhangandeStudier', formData.sammanhangandeStudier)
        formData.studieintygFiles.forEach((file: File) => {
            fData.append('files', file, file.name)
        })

        const res = await uploadStudieintyg(data.id, fData, id_token, klientId)
        setApiResponse(res)
        await queryClient.invalidateQueries(['getStudieintyg'])

        trackClick('button', { label: t('skicka') })
    }

    return (
        <PageWrapper isLoading={isFetching} errorLoadingData={isError} errorText={''} testSelector="studiestod-studieintyg-wrapper">
            <FormProvider {...formMethods}>
                {!isFetching && (
                    <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <StudieintygForm ansokan={data} />
                        <StudieintygDocuments ansokanId={ansokanId} arendeId={arendeId} />
                    </Form>
                )}
                <SubmitResponse
                    apiResponse={apiResponse}
                    successMessage="Studieintyget har laddats upp"
                    failureMessage="Uppladdning misslyckades"
                />
                {RenderUnsavedChangesPrompt}
            </FormProvider>
        </PageWrapper>
    )
}

export default StudiestodStudieintyg
