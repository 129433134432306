export interface LaddaUppStudieintygEpiContent {
  datumForStudiestartHarInteInfallitBanner: string
  tidigareUppladdadeStudieintygHeader: string

  description1: string
  description2: string
  description3: string
  description4: string

  infoOmStudieintygHeader: string
  infoOmStudieintygDropdownHeader: string
  infoOmStudieintygDropdownLabel: string

  infoOmStudieintygRadioButtonVartermin: string
  infoOmStudieintygRadioButtonHosttermin: string
  infoOmStudieintygRadioButtonLasar: string

  infoOmStudieintygRadioButtonSammanhangandeStudierHeader: string
  infoOmStudieintygRadioButtonKontinuerligaStudier: string
  infoOmStudieintygRadioButtonJulledight: string
  infoOmStudieintygRadioButtonSommarledighet: string

  checklistForStudieintygHeader: string

  checklistForStudieintygRadioButtonSynsFornamnOchEfternamnHeader: string

  checklistForStudieintygRadioButtonSynsPersonnummer: string

  checklistForStudieintygRadioButtonFinnsUtbildningsnamn: string

  checklistForStudieintygRadioButtonFinnsStudieomfattning: string
  checklistForStudieintygRadioButtonFinnsStudieomfattningTooltip: string

  checklistForStudieintygRadioButtonTerminstartAndTerminslut: string
  checklistForStudieintygRadioButtonTerminstartAndTerminslutTooltip: string

  checklistForStudieintygRadioButtonDateratTidigastBeforeAktuellTerminStart: string

  checklistForStudieintygRadioButtonJa: string
  checklistForStudieintygRadioButtonNej: string

  laggTillFilerButtonText: string
  laddaUppWorkspaceHeader: string

  avbrytModalHeader: string
  avbrytModalDescription: string

  skickaButtonText: string
  avbrytButtonText: string

  avbrytModalJaButton: string
  avbrytModalNejButton: string

  laddaUppStudieintygButton: string

  lamnaIfylltFormularWarningText: string

  isDatumForTerminsstartAndTerminsslutIncludedErrorMessage: string
  isFornamnEfternamnVisibleErrorMessage: string
  isPersonnummerIncludedErrorMessage: string
  isStudieintygetDateratTidigastBeforeTerminStartIncludedErrorMessage: string
  isStudieomfattningenIncludedErrorMessage: string
  isUtbildningensNamnIncludedErrorMessage: string
  sammanhangandeStudierErrorMessage: string
  terminErrorMessage: string
  fileUploadHasErrorText: string
  fileUploadHasErrorsText: string
  fileUploadInformationText: string
}

export interface StudieintygFelmeddelandeEpiContent {
  systemError: string
  valideringError: string
  fileSizeErrorMessage: string
  fileFormatErrorMessage: string
  fileNameErrorMessage: string
}
export interface StudieintygEpiContent {
  laddauppstudieintyg: LaddaUppStudieintygEpiContent
  felmeddelanden: StudieintygFelmeddelandeEpiContent
  studieintygMall: {
    studieintygMallLink: string
    studieintygMallLinkText: string
  }
  infoCsnOmstallningsstod: {
    readMoreLink: string
    readMoreLinkText: string
  }
}

export const defaultLaddaUppStudieintygEpiContent: LaddaUppStudieintygEpiContent = {
  datumForStudiestartHarInteInfallitBanner: 'epi:datumForStudiestartHarInteInfallitBanner',
  tidigareUppladdadeStudieintygHeader: 'epi:tidigareUppladdadeStudieintygHeader',
  description1: 'epi:description1',
  description2: 'epi:description2',
  description3: 'epi:description3',
  description4: 'epi:description4',
  infoOmStudieintygHeader: 'epi:infoOmStudieintygHeader',
  infoOmStudieintygDropdownHeader: 'epi:infoOmStudieintygDropdownHeader',
  infoOmStudieintygDropdownLabel: 'epi:infoOmStudieintygDropdownLabel',
  infoOmStudieintygRadioButtonVartermin: 'epi:infoOmStudieintygRadioButtonVartermin',
  infoOmStudieintygRadioButtonHosttermin: 'epi:infoOmStudieintygRadioButtonHosttermin',
  infoOmStudieintygRadioButtonLasar: 'epi:infoOmStudieintygRadioButtonLasar',
  infoOmStudieintygRadioButtonSammanhangandeStudierHeader: 'epi:infoOmStudieintygRadioButtonSammanhangandeStudierHeader',
  infoOmStudieintygRadioButtonKontinuerligaStudier: 'epi:infoOmStudieintygRadioButtonKontinuerligaStudier',
  infoOmStudieintygRadioButtonJulledight: 'epi:infoOmStudieintygRadioButtonJulledight',
  infoOmStudieintygRadioButtonSommarledighet: 'epi:infoOmStudieintygRadioButtonSommarledighet',
  checklistForStudieintygHeader: 'epi:checklistForStudieintygHeader',
  checklistForStudieintygRadioButtonSynsFornamnOchEfternamnHeader: 'epi:checklistForStudieintygRadioButtonSynsFornamnOchEfternamnHeader',
  checklistForStudieintygRadioButtonSynsPersonnummer: 'epi:checklistForStudieintygRadioButtonSynsPersonnummer',
  checklistForStudieintygRadioButtonFinnsUtbildningsnamn: 'epi:checklistForStudieintygRadioButtonFinnsUtbildningsnamn',
  checklistForStudieintygRadioButtonFinnsStudieomfattning: 'epi:checklistForStudieintygRadioButtonFinnsStudieomfattning',
  checklistForStudieintygRadioButtonFinnsStudieomfattningTooltip: 'epi:checklistForStudieintygRadioButtonFinnsStudieomfattningTooltip',
  checklistForStudieintygRadioButtonTerminstartAndTerminslut: 'epi:checklistForStudieintygRadioButtonTerminstartAndTerminslut',
  checklistForStudieintygRadioButtonTerminstartAndTerminslutTooltip:
    'epi:checklistForStudieintygRadioButtonTerminstartAndTerminslutTooltip',
  checklistForStudieintygRadioButtonDateratTidigastBeforeAktuellTerminStart:
    'epi:checklistForStudieintygRadioButtonDateratTidigastBeforeAktuellTerminStart',
  checklistForStudieintygRadioButtonJa: 'epi:checklistForStudieintygRadioButtonJa',
  checklistForStudieintygRadioButtonNej: 'epi:checklistForStudieintygRadioButtonNej',
  laggTillFilerButtonText: 'epi:laggTillFilerButtonText',
  laddaUppWorkspaceHeader: 'epi:laddaUppWorkspaceHeader',
  avbrytModalHeader: 'epi:avbrytModalHeader',
  avbrytModalDescription: 'epi:avbrytModalDescription',
  skickaButtonText: 'epi:skickaButtonText',
  avbrytButtonText: 'epi:avbrytButtonText',
  avbrytModalJaButton: 'epi:avbrytModalJaButton',
  avbrytModalNejButton: 'epi:avbrytModalNejButton',
  laddaUppStudieintygButton: 'epi:laddaUppStudieintygButton',
  lamnaIfylltFormularWarningText: 'epi:lamnaIfylltFormularWarningText',
  isDatumForTerminsstartAndTerminsslutIncludedErrorMessage: 'epi:isDatumForTerminsstartAndTerminsslutIncludedErrorMessage',
  isFornamnEfternamnVisibleErrorMessage: 'epi:isFornamnEfternamnVisibleErrorMessage',
  isPersonnummerIncludedErrorMessage: 'epi:isPersonnummerIncludedErrorMessage',
  isStudieintygetDateratTidigastBeforeTerminStartIncludedErrorMessage:
    'epi:isStudieintygetDateratTidigastBeforeTerminStartIncludedErrorMessage',
  isStudieomfattningenIncludedErrorMessage: 'epi:isStudieomfattningenIncludedErrorMessage',
  isUtbildningensNamnIncludedErrorMessage: 'epi:isUtbildningensNamnIncludedErrorMessage',
  sammanhangandeStudierErrorMessage: 'epi:sammanhangandeStudierErrorMessage',
  terminErrorMessage: 'epi:terminErrorMessage',
  fileUploadHasErrorText: 'epi:fileUploadHasErrorText',
  fileUploadHasErrorsText: 'epi:fileUploadHasErrorsText',
  fileUploadInformationText: 'epi:fileUploadInformationText',
}
