import Icons from '@local/src/Components/Icons'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, Typography } from '@mui/material'
import { isNil } from 'ramda'
import React, { useEffect, useState } from 'react'
import { UseFieldArrayRemove, useFormContext } from 'react-hook-form'

import { StudiestodAnsokanFormData } from '../../../Models/StudiestodAnsokanForm.types'

import CsnInformationForm from './Components/CsnInformationForm/CsnInformationForm'
import { UtbildningFormEpiContent } from './UtbildningForm.content'
import { Anordnare, Kursavgift, Omfattning, Studiestart, Utbildningslangd, Utbildningsnamn, Utbildningstyp } from './UtbildningFormInputs'

interface Props {
    index: number
    content: UtbildningFormEpiContent
    remove: UseFieldArrayRemove
}

export const UtbildningForm = ({ index, content, remove }: Props) => {
    const {
        formState: { errors },
        watch,
    } = useFormContext<StudiestodAnsokanFormData>()

    const [isExpanded, setIsExpanded] = useState<boolean>(false)

    const { utbildningar, isLegacyAnsokan, isReadonly } = watch()
    const currentUtbildning = utbildningar[index]
    const currentErrors = errors.utbildningar?.[index]
    const isLastItem = index + 1 === utbildningar.length
    const hasError = !isNil(currentErrors)

    const handleRemoveClicked = () => remove(index)

    const handleOnChange = () => {
        setIsExpanded(!isExpanded)
    }

    useEffect(() => {
        setIsExpanded(isLastItem)
    }, [isLastItem, setIsExpanded])

    const showRemoveButton = !isReadonly && !isLegacyAnsokan
    const heading = currentUtbildning.utbildningsnamn || content.ansokanHeadingPlaceholder

    return (
        <Accordion
            sx={(theme) => ({
                border: `1px solid ${hasError ? theme.palette.error.main : theme.palette.grey[400]}`,
                borderRadius: 1,
            })}
            defaultExpanded={false}
            expanded={isExpanded}
            onChange={handleOnChange}
            elevation={0}
            TransitionProps={{
                timeout: 200,
            }}
        >
            <AccordionSummary
                aria-controls={`accordion-utbildning-${index}-content`}
                id={`accordion-utbildning-${index}-header`}
                expandIcon={<ExpandMoreIcon />}
            >
                <Typography
                    variant="h6"
                    fontSize="1rem"
                    sx={(theme) => ({
                        wordBreak: 'break-word',
                        [theme.breakpoints.down('sm')]: {
                            maxWidth: '350px',
                        },
                    })}
                >
                    {heading}
                </Typography>
            </AccordionSummary>

            <AccordionDetails>
                <Stack spacing={3}>
                    <Stack spacing={3}>
                        <Typography variant="h6">{content.vilkenUtbildningText}</Typography>
                        <Utbildningsnamn content={content} index={index} isReadonly={isReadonly} />
                        <Anordnare content={content} index={index} isReadonly={isReadonly} />
                        <Utbildningstyp content={content} index={index} isReadonly={isReadonly} />
                        <Omfattning content={content} index={index} isReadonly={isReadonly} />
                        <Studiestart content={content} index={index} isReadonly={isReadonly} />
                    </Stack>

                    <Stack spacing={2}>
                        <Typography variant="h6">{content.utbildningLangdText}</Typography>
                        <Utbildningslangd content={content} index={index} isReadonly={isReadonly} />
                        <Kursavgift content={content} index={index} isReadonly={isReadonly} />
                    </Stack>

                    {isLegacyAnsokan && <CsnInformationForm index={index} content={content} />}

                    {showRemoveButton && (
                        <Box display="flex" justifyContent="flex-end">
                            <Button variant="outlined" onClick={handleRemoveClicked} data-testid="remove-button">
                                <Icons.Delete />
                                <Typography>{content.removeButtonText}</Typography>
                            </Button>
                        </Box>
                    )}
                </Stack>
            </AccordionDetails>
        </Accordion>
    )
}
