import { useIsFeatureEnabled } from '@trr/app-shell-data'
import { useTrackCustomClick, useTrackVirtualView } from '@trr/gtm-tracking'

const useGTMTracking = () => {
    const isFeatureEnabled = useIsFeatureEnabled('gtm.enabled.studiestod')

    const trackCustomClick = useTrackCustomClick()
    const trackCustomView = useTrackVirtualView('studiestod')

    type TrackFunc = (...params: unknown[]) => unknown

    const trackIfEnabled = <T extends TrackFunc>(func: T, ...args: Parameters<T>) => {
        if (isFeatureEnabled) {
            return func(...args)
        }
    }
    return {
        trackClick: (...args: Parameters<typeof trackCustomClick>) => trackIfEnabled(trackCustomClick, ...args),
        trackView: (...args: Parameters<typeof trackCustomView>) => trackIfEnabled(trackCustomView, ...args),
    }
}

export default useGTMTracking
