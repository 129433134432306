import FilePickerButton from '@local/src/Components/FilePickerButton'
import FileUploadWrapper from '@local/src/Features/StudiestodStudieintyg/Components/StudieintygForm/Components/UploadStudieintyg/Components/FileUploadWrapper/FileUploadWrapper'
import { StudieintygEnum } from '@local/src/Features/StudiestodStudieintyg/Components/StudieintygForm/Components/UploadStudieintyg/Models/StudieintygTypes'
import {
    studieintygFileSizeLimitInKb,
    studieintygValidFileFormats,
    useStudieintygFilePicker,
} from '@local/src/Features/StudiestodStudieintyg/Hooks/useStudieintygFilePicker'
import { StudieintygFormModel } from '@local/src/Features/StudiestodStudieintyg/Models/StudieintygFormModel'
import { usePickEpiContentWithFallback } from '@local/src/Hooks'
import React, { Fragment, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import SelectedStudieintygFiles from './Components/SelectedStudieintygFiles/SelectedStudieintygFiles'

const UploadStudieintyg = () => {
    const {
        watch,
        setValue,
        formState: { isSubmitSuccessful },
    } = useFormContext<StudieintygFormModel>()

    const { studieintygFiles } = watch()

    const {
        studiestodStudieintyg: { laddauppstudieintyg: content },
    } = usePickEpiContentWithFallback()

    const { onError, onPick, allFileListError, selectedFiles, hasError, clearFile, resetFiles } = useStudieintygFilePicker()

    useEffect(() => {
        if (!isSubmitSuccessful) return
        resetFiles()
    }, [isSubmitSuccessful, resetFiles])

    useEffect(() => {
        setValue('studieintygFiles', selectedFiles)
    }, [selectedFiles, setValue])

    const hasSelectedStudieintygFiles = studieintygFiles.length > 0

    return (
        <Fragment>
            {hasSelectedStudieintygFiles ? (
                <SelectedStudieintygFiles
                    fileError={allFileListError}
                    hasError={hasError}
                    onError={onError}
                    onPick={onPick}
                    clearFile={clearFile}
                    resetFiles={resetFiles}
                />
            ) : (
                <FileUploadWrapper hasError={hasError} fileListError={allFileListError}>
                    <FilePickerButton
                        onPickValid={onPick}
                        onPickError={onError}
                        inputName={StudieintygEnum.STUDIEINTYG_FILES}
                        fileSizeLimit={studieintygFileSizeLimitInKb}
                        validExtensions={studieintygValidFileFormats}
                        value={content?.laddaUppStudieintygButton}
                        multiple
                    >
                        {content?.laddaUppStudieintygButton}
                    </FilePickerButton>
                </FileUploadWrapper>
            )}
        </Fragment>
    )
}

export default UploadStudieintyg
