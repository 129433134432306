import { DatePicker, Dropdown, InputField } from '@local/src/Components/FormComponents'
import { RadioButton, RadioButtonGroup } from '@local/src/Components/FormComponents/RadioButtonGroup/RadioButtonGroup'
import InfoPopover from '@local/src/Components/InfoPopover'
import { Box, Stack } from '@mui/material'
import React from 'react'

import { omfattningLabels, utbildningLangdEnhetLabels, utbildningstypLabels } from './Models/Utbildningsform.data'
import { UtbildningFormEpiContent } from './UtbildningForm.content'

interface Props {
    content: UtbildningFormEpiContent
    index: number
    isReadonly: boolean
}

export const Utbildningsnamn = ({ content, index, isReadonly }: Props) => (
    <InputField
        placeholder={content.utbildningsnamnPlaceholderText}
        label={content.utbildningsnamnText}
        name={`utbildningar[${index}].utbildningsnamn`}
        disabled={isReadonly}
    />
)

export const Anordnare = ({ content, index, isReadonly }: Props) => (
    <InputField
        placeholder={content.anordnarePlaceholderText}
        label={content.anordnareText}
        name={`utbildningar[${index}].anordnare`}
        disabled={isReadonly}
    />
)
export const Utbildningstyp = ({ content, index, isReadonly }: Props) => (
    <Stack direction="row" spacing={2} alignItems="center">
        <Dropdown
            name={`utbildningar[${index}].utbildningstyp`}
            selectOptions={utbildningstypLabels}
            label={content.utbildningstypText}
            isReadOnly={isReadonly}
            testId={`utbildningar[${index}].utbildningstyp`}
        />
        <InfoPopover content={content.utbildningstypTooltip} />
    </Stack>
)

export const Omfattning = ({ content, index, isReadonly }: Props) => (
    <Stack direction="row" spacing={2} alignItems="center">
        <Dropdown
            name={`utbildningar[${index}].omfattning`}
            selectOptions={omfattningLabels}
            label={content.omfattningText}
            isReadOnly={isReadonly}
            testId={`utbildningar[${index}].omfattning`}
        />
        <InfoPopover content={content.omfattningTooltip} />
    </Stack>
)

export const Studiestart = ({ content, index, isReadonly }: Props) => (
    <DatePicker name={`utbildningar[${index}].studiestart`} label={content.studiestartText} disabled={isReadonly} />
)

export const Utbildningslangd = ({ content, index, isReadonly }: Props) => (
    <Stack direction="row" spacing={2}>
        <Box flex={1}>
            <InputField
                name={`utbildningar[${index}].antalTerminer`}
                label={content.utbildningLangdText}
                disabled={isReadonly}
                placeholder={content.utbildningLangdPlaceholderText}
                inputType="number"
                parseAsNumber={false}
            />
        </Box>
        <Box flex={1}>
            <Dropdown
                name={`utbildningar[${index}].utbildningLangdEnhet`}
                selectOptions={utbildningLangdEnhetLabels}
                label={content.utbildningLangdEnhetText}
                isReadOnly={isReadonly}
                testId={`utbildningar[${index}].utbildningLangdEnhet`}
            />
        </Box>
    </Stack>
)

export const Kursavgift = ({ content, index, isReadonly }: Props) => {
    const radios: RadioButton[] = [
        {
            label: 'Ja',
            value: 1,
            disabled: isReadonly,
        },
        {
            label: 'Nej',
            value: 2,
            disabled: isReadonly,
        },
        {
            label: 'Vet ej',
            value: 0,
            disabled: isReadonly,
        },
    ]
    return (
        <RadioButtonGroup
            valueType="number"
            label={content.kursavgiftText}
            name={`utbildningar[${index}].isKoptUtbildning`}
            radios={radios}
        />
    )
}
