export interface RaknesnurraEpiContent {
    berakningMisslyckadText: string
    header: string
    kalenderhalvarText: string
    beraknaButtonText: string
    fodelsedatumLabelText: string
    lonLabelText: string
    studietaktLabelText: string
    studietaktSelectOption50: string
    studietaktSelectOption75: string
    studietaktSelectOption100: string
    studiestartLabelText: string
    kalenderHalvarAntalVeckorLabelText: string
    kalenderHalvarAntalVeckorAddButtonText: string
    kalenderHalvarAntalVeckorRemoveButtonText: string
    resultatHeader: string
    addKalenderHalvarInformation: string
    removeKalenderHalvarInformation: string
    addRemoveInformationText: string
    veckorText: string
    veckaText: string
    perDagText: string
    perVeckaText: string
    totaltText: string
    isYoungerThan40Text: string
    is65OrOlderText: string
    saknarRattigheterText: string
    csnCoversErsattningsprocentText: string
    raknesnurraHeaderTooltip: string
    kalenderhalvarHeaderTooltip: string
    resultatHeaderTooltip: string
    resultatKalenderhalvarTooltip: string
    validationRequiredFodelsedatum: string
    validationRequiredLoneniva: string
    validationRequiredStudietakt: string
    validationRequiredBeraknadStudiestart: string
    validationRequiredAntalVeckor: string
    validationPositiveLoneniva: string
}

export const defaultRaknesnurraEpiContent: RaknesnurraEpiContent = {
    header: 'epi:header',
    kalenderhalvarText: 'epi:kalenderhalvarText',
    beraknaButtonText: 'epi:beraknaButtonText',
    fodelsedatumLabelText: 'epi:fodelsedatumLabelText',
    lonLabelText: 'epi:lonLabelText',
    studietaktLabelText: 'epi:studietaktLabelText',
    studietaktSelectOption50: 'epi:studietaktSelectOption50',
    studietaktSelectOption75: 'epi:studietaktSelectOption75',
    studietaktSelectOption100: 'epi:studietaktSelectOption100',
    studiestartLabelText: 'epi:studiestartLabelText',
    kalenderHalvarAntalVeckorLabelText: 'epi:kalenderHalvarAntalVeckorLabelText',
    kalenderHalvarAntalVeckorAddButtonText: 'epi:kalenderHalvarAntalVeckorAddButtonText',
    kalenderHalvarAntalVeckorRemoveButtonText: 'epi:kalenderHalvarAntalVeckorRemoveButtonText',
    resultatHeader: 'epi:resultatHeader',
    addKalenderHalvarInformation: 'epi:addKalenderHalvarInformation',
    removeKalenderHalvarInformation: 'epi:removeKalenderHalvarInformation',
    addRemoveInformationText: 'epi:addRemoveInformationText',
    veckorText: 'epi:veckorText',
    perDagText: 'epi:perDagText',
    perVeckaText: 'epi:perVeckaText',
    totaltText: 'epi:totaltText',
    isYoungerThan40Text: 'epi:isYoungerThan40Text',
    is65OrOlderText: 'epi:is65OrOlderText',
    saknarRattigheterText: 'epi:saknarRattigheterText',
    veckaText: 'epi:veckaText',
    csnCoversErsattningsprocentText: 'epi:veckaText',
    raknesnurraHeaderTooltip: 'epi:raknesnurraHeaderTooltip',
    kalenderhalvarHeaderTooltip: 'epi:kalenderhalvarHeaderTooltip',
    resultatHeaderTooltip: 'epi:resultatHeaderTooltip',
    resultatKalenderhalvarTooltip: 'epi:resultatKalenderhalvarTooltip',
    validationRequiredFodelsedatum: 'epi:validationRequiredFodelsedatum',
    validationRequiredLoneniva: 'epi:validationRequiredLoneniva',
    validationRequiredStudietakt: 'epi:validationRequiredStudietakt',
    validationRequiredBeraknadStudiestart: 'epi:validationRequiredBeraknadStudiestart',
    validationRequiredAntalVeckor: 'epi:validationRequiredAntalVeckor',
    validationPositiveLoneniva: 'epi:validationPositiveLoneniva',
    berakningMisslyckadText: 'epi:berakningMisslyckadText',
}
