import { StudiestodApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { hasUtbildningstyp } from '@local/src/Utils/Helpers/StudiestodHelper'
import { array as yupArray, boolean as yupBoolean, object as yupObject, SchemaOf, string as yupString, StringSchema } from 'yup'

import { UtbildningsEnum } from '../../StudiestodAnsokanForm/Components/Utbildningar/UtbildningForm/Models/Utbildningsform.types'
import { StudieintygFormModel } from '../Models/StudieintygFormModel'
import { LaddaUppStudieintygEpiContent } from '../StudiestodStudieintyg.content'

export const StudieintygFormModelSchema = (
  data: StudiestodApiModel,
  content: LaddaUppStudieintygEpiContent
): SchemaOf<StudieintygFormModel> =>
  yupObject({
    avserAr: yupString().required(content.terminErrorMessage).nullable(),
    termin: yupString().required(content.terminErrorMessage).nullable(),
    sammanhangandeStudier: yupString()
      .nullable()
      .when({
        is: () => hasUtbildningstyp(data, UtbildningsEnum.YRKESHOGSKOLA),
        then: (value: StringSchema) => value.required(content.sammanhangandeStudierErrorMessage),
      }),
    isDatumForTerminsstartAndTerminsslutIncluded: yupBoolean()
      .nullable()
      .required(content.isDatumForTerminsstartAndTerminsslutIncludedErrorMessage)
      .oneOf([true], content.isDatumForTerminsstartAndTerminsslutIncludedErrorMessage),
    isFornamnEfternamnVisible: yupBoolean()
      .nullable()
      .required(content.isFornamnEfternamnVisibleErrorMessage)
      .oneOf([true], content.isFornamnEfternamnVisibleErrorMessage),
    isPersonnummerIncluded: yupBoolean()
      .nullable()
      .required(content.isPersonnummerIncludedErrorMessage)
      .oneOf([true], content.isPersonnummerIncludedErrorMessage),
    isStudieintygetDateratTidigastBeforeTerminStartIncluded: yupBoolean()
      .nullable()
      .required(content.isStudieintygetDateratTidigastBeforeTerminStartIncludedErrorMessage)
      .oneOf([true], content.isStudieintygetDateratTidigastBeforeTerminStartIncludedErrorMessage),
    isStudieomfattningenIncluded: yupBoolean()
      .nullable()
      .required(content.isStudieomfattningenIncludedErrorMessage)
      .oneOf([true], content.isStudieomfattningenIncludedErrorMessage),
    isUtbildningensNamnIncluded: yupBoolean()
      .nullable()
      .required(content.isUtbildningensNamnIncludedErrorMessage)
      .oneOf([true], content.isUtbildningensNamnIncludedErrorMessage),
    studieintygFiles: yupArray(),
  })
