import { Dropdown } from '@local/src/Components/FormComponents/Dropdown'
import { usePickEpiContentWithFallback } from '@local/src/Hooks'
import React from 'react'

const Studietakt = () => {
    const { studiestodRaknesnurra: epiContent } = usePickEpiContentWithFallback()
    const studietaktSelectOption = [
        { value: 100, label: epiContent.studietaktSelectOption100 },
        { value: 75, label: epiContent.studietaktSelectOption75 },
        { value: 50, label: epiContent.studietaktSelectOption50 },
    ]
    return (
        <Dropdown
            name="studietakt"
            selectOptions={studietaktSelectOption}
            label={'Studietakt'}
            testId={`raknesnurra-form-studietakt-dropdown`}
        />
    )
}

export default Studietakt
