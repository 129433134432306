import { DependencyList, EffectCallback, useCallback, useEffect, useRef } from 'react'

const useDebouncedEffect = (effect: EffectCallback, delay: number, deps: DependencyList) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const callback = useCallback(effect, deps)
    const hasMounted = useRef(false)

    useEffect(() => {
        if (hasMounted.current) {
            const handler = setTimeout(() => {
                callback()
            }, delay)

            return () => {
                clearTimeout(handler)
            }
        } else {
            hasMounted.current = true
        }
    }, [callback, delay])
}

export default useDebouncedEffect
