import StatusChip from '@local/src/Features/StudiestodAnsokanList/Components/AnsokanItemCard/Components/StatusChip'
import { StudiestodListApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { mapStudiestodAnsokanStateToStatus } from '@local/src/Utils/Helpers/StudiestodHelper'
import { formatDate } from '@local/src/Utils/Helpers/formatDate'
import { Alert, AlertTitle, Card, CardContent, CardHeader, Chip, List, ListItem, ListItemText, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import AnsokanItemCardActions from './Components/AnsokanItemCardActions'

interface Props {
    ansokan: StudiestodListApiModel
    isEjAktuell?: boolean
}
const AnsokanItemCard = ({ ansokan, isEjAktuell = false }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokanList' })

    const {
        ansokanNamn,
        utbildningar,
        id,
        arendeId,
        hasStudieintyg,
        studiestodNummer,
        isRedigerbar,
        status: studiestodAnsokanState,
        isDeletable,
        senastUppdateradDatum,
        ejAktuell,
        isBeslutad,
        canUploadStudieintyg,
    } = ansokan
    const status = mapStudiestodAnsokanStateToStatus(studiestodAnsokanState)

    return (
        <Card data-testid={`studiestod-list-item-${isEjAktuell ? 'ejaktuell' : 'aktuell'}`}>
            <CardHeader
                sx={{ flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'flex-start' }}
                title={studiestodNummer ? `#${studiestodNummer} - ${ansokanNamn}` : ansokanNamn}
                titleTypographyProps={{ variant: 'h5', sx: { wordBreak: 'break-word' } }}
                action={<StatusChip status={status} />}
            />
            <CardContent>
                <Stack spacing={2}>
                    <Stack direction="row">
                        <Typography>{`${t('ejAktuell.timestamp_lastchangedAt')} ${formatDate(senastUppdateradDatum)}`}</Typography>
                    </Stack>
                    {isEjAktuell && (
                        <Alert severity="warning">
                            <AlertTitle>{t('ejAktuell.alert_title')}</AlertTitle>
                            <Typography variant="body2">{ejAktuell?.orsakKund}</Typography>
                        </Alert>
                    )}
                    <List>
                        {utbildningar.map((utbildning, index) => (
                            <ListItem divider={index !== utbildningar.length - 1} key={utbildning.id}>
                                <Stack
                                    flex={1}
                                    mb={{ xs: 1, sm: 'inherit' }}
                                    direction={{ xs: 'column', sm: 'row' }}
                                    justifyContent="space-between"
                                    alignItems={{ xs: 'left', sm: 'center' }}
                                >
                                    <ListItemText
                                        primary={<Typography sx={{ wordBreak: 'break-word' }}>{utbildning.utbildningsnamn}</Typography>}
                                        secondary={utbildning.anordnare}
                                    />
                                    {isBeslutad && (
                                        <Chip
                                            color="primary"
                                            label={
                                                utbildning.isStodNekat
                                                    ? t('utbildningListItem.chip_ejBeviljad')
                                                    : t('utbildningListItem.chip_beviljad')
                                            }
                                            data-testid={`beviljad-chip-${utbildning.isStodNekat ? 'ejBeviljad' : 'beviljad'}`}
                                        />
                                    )}
                                </Stack>
                            </ListItem>
                        ))}
                    </List>
                </Stack>
            </CardContent>
            <AnsokanItemCardActions
                status={status}
                hasStudieintyg={hasStudieintyg}
                isRedigerbar={isRedigerbar}
                arendeId={arendeId}
                ansokanId={id}
                isDeletable={isDeletable}
                canUploadStudieintyg={canUploadStudieintyg}
            />
        </Card>
    )
}

export default AnsokanItemCard
