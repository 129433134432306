import { FileListError } from '@local/src/Hooks/useFilePicker'
import usePickEpiContentWithFallback from '@local/src/Hooks/usePickEpiContentWithFallback'
import { Box, Typography } from '@mui/material'
import React from 'react'

import FileUploadError from '../FileUploadError/FileUploadError'

interface Props {
    fileListError: FileListError
    hasError: boolean
}
const FileUploadWrapper = ({ children, fileListError, hasError }: React.PropsWithChildren<Props>) => {
    const {
        studiestodStudieintyg: { laddauppstudieintyg: content },
    } = usePickEpiContentWithFallback()

    return (
        <Box>
            {children}
            <Typography mt={2} mb={2}>
                {content?.fileUploadInformationText}
            </Typography>
            <FileUploadError fileListError={fileListError} hasError={hasError} />
        </Box>
    )
}

export default FileUploadWrapper
