import { UtbildningsEnum } from '@local/src/Features/StudiestodAnsokanForm/Components/Utbildningar/UtbildningForm/Models/Utbildningsform.types'
import usePickEpiContentWithFallback from '@local/src/Hooks/usePickEpiContentWithFallback'
import { StudiestodApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { hasUtbildningstyp } from '@local/src/Utils/Helpers/StudiestodHelper'
import { Typography } from '@mui/material'
import React, { Fragment } from 'react'

import { AvserAr, SammanhangandeStudier, Termin } from './InformationStudieintygElements'

interface Props {
    ansokan: StudiestodApiModel
}

const InformationStudieintyg = ({ ansokan }: Props) => {
    const {
        studiestodStudieintyg: { laddauppstudieintyg: content },
    } = usePickEpiContentWithFallback()

    return (
        <Fragment>
            <Typography variant="h3" mt={2}>
                {content?.infoOmStudieintygHeader}
            </Typography>
            <Typography mb={2}>{content?.infoOmStudieintygDropdownHeader}</Typography>
            <AvserAr content={content} />
            <Termin content={content} />
            {hasUtbildningstyp(ansokan, UtbildningsEnum.YRKESHOGSKOLA) && <SammanhangandeStudier content={content} />}
        </Fragment>
    )
}

export default InformationStudieintyg
