export const scrollElementIntoView = (el: HTMLElement, offsetTop = 0, timeout = 0) => {
    if (el) {
        setTimeout(() => {
            const currentPosition = el.style.position
            const currentTop = el.getBoundingClientRect().top.toString()
            el.style.position = 'relative'
            el.style.top = `${offsetTop}px`
            el.scrollIntoView({ behavior: 'smooth', block: 'start' })
            el.style.top = currentTop
            el.style.position = currentPosition
        }, timeout)
    }
}

export const waitForRender = (func: (...args: unknown[]) => void, timeout = 0) => {
    setTimeout(() => func(), timeout)
}
