import { MotiveringApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { object as yupObject, SchemaOf, string as yupString } from 'yup'

import { ValideringsmeddelandenEpiContent } from './StudiestodAnsokanFormSchema.content'

export const StudiestodFormMotiveringSchema = (content: ValideringsmeddelandenEpiContent): SchemaOf<MotiveringApiModel> =>
  yupObject({
    nyanstallning: yupString().nullable().trim().required(content.motiveringNyanstallning),
    arbetsmarknad: yupString().nullable().trim().required(content.motiveringArbetsmarknad),
    kompletteringErfarenhet: yupString().nullable().trim().required(content.motiveringKompletteringErfarenhet),
    utbildningsalternativ: yupString().nullable().trim().required(content.motiveringUtbildningsalternativ),
  })
