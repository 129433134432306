import Icons from '@local/src/Components/Icons/Icons'
import { scrollElementIntoView, waitForRender } from '@local/src/Utils/Helpers/viewHelpers'
import { Box, Button, FormHelperText, Stack, Typography } from '@mui/material'
import React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { StudiestodAnsokanFormData } from '../../Models/StudiestodAnsokanForm.types'
import { ValideringsmeddelandenEpiContent } from '../../Schemas/StudiestodAnsokanFormSchema.content'

import { UtbildningarEpiContent } from './Utbildningar.content'
import { UtbildningForm } from './UtbildningForm/UtbildningForm'

interface Props {
    content: UtbildningarEpiContent
    validationContent: ValideringsmeddelandenEpiContent
}

const Utbildningar = ({ content, validationContent }: Props) => {
    const {
        control,
        watch,
        trigger,
        formState: { errors },
        setFocus,
    } = useFormContext<StudiestodAnsokanFormData>()

    const { isLegacyAnsokan, isReadonly } = watch()
    const { append, fields, remove } = useFieldArray({
        control,
        name: 'utbildningar',
    })
    const showAddButton = !isReadonly && !isLegacyAnsokan

    const hasRootUtbildningarError = Boolean(errors.utbildningar?.message)

    const handleAddClicked = async () => {
        append({
            utbildningsnamn: '',
            anordnare: '',
            utbildningstyp: undefined,
            omfattning: undefined,
            studiestart: undefined,
            antalTerminer: undefined,
            utbildningLangdEnhet: undefined,
            isKoptUtbildning: undefined,
        })

        if (hasRootUtbildningarError) await trigger('utbildningar')

        waitForRender(() => {
            setFocus(`utbildningar.${fields.length}.utbildningsnamn`)
            const scrollToIndex = Math.max(0, fields.length - 1)
            const inputElement = document.getElementById(`accordion-utbildning-${scrollToIndex}-header`)
            scrollElementIntoView(inputElement, 0, 210)
        }, 100)
    }

    return (
        <Stack spacing={3}>
            <Stack spacing={1}>
                <Typography variant="h4">{content.utbildningarHeaderText}</Typography>
                <Typography>{content.utbildningarLeadText}</Typography>
            </Stack>

            {fields.map((_, index) => (
                <UtbildningForm key={index} index={index} content={content} remove={remove} />
            ))}
            {showAddButton && (
                <Box>
                    <Button variant="outlined" onClick={handleAddClicked} data-testid="add-utbildning-button">
                        <Stack direction="row" spacing={1}>
                            <Icons.Add />
                            <Typography>{content.addUtbildningButtonText}</Typography>
                        </Stack>
                    </Button>
                </Box>
            )}

            {hasRootUtbildningarError && (
                <FormHelperText data-testid="utbildningar-error" error>
                    {validationContent.utbildningRequired}
                </FormHelperText>
            )}
        </Stack>
    )
}

export default Utbildningar
