import EjAktuellStudiestodAnsokanList from '@local/src/Features/StudiestodAnsokanList/EjAktuellStudiestodAnsokanList'
import StudiestodAnsokanList from '@local/src/Features/StudiestodAnsokanList/StudiestodAnsokanList'
import { Stack } from '@mui/material'
import React from 'react'

const StudiestodAnsokanListPage = () => (
    <Stack maxWidth="md">
        <StudiestodAnsokanList />
        <EjAktuellStudiestodAnsokanList />
    </Stack>
)

export default StudiestodAnsokanListPage
