import React from 'react'
import { FormControl, InputAdornment, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Controller, useFormContext } from 'react-hook-form'
import { NumericFormat } from 'react-number-format'
import { isNil } from 'ramda'
import { getErrorMessage } from '@local/src/Features/KompletterandeStudiestodRaknesnurraForm/Helpers/getErrorMessage'

interface Props {
    isFetching: boolean
}
const ManadslonInput = ({ isFetching }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'kompletterandeStudiestodRaknesnurraForm' })
    const { getFieldState, control } = useFormContext()
    const { error } = getFieldState('manadslon')
    return (
        <FormControl fullWidth>
            <Controller
                name="manadslon"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                    <NumericFormat
                        data-testid="manadslon"
                        inputRef={field.ref}
                        disabled={field.disabled}
                        value={field.value}
                        onValueChange={(values) => field.onChange(values.floatValue)}
                        customInput={TextField}
                        label={t('textFieldLabel')}
                        error={Boolean(error?.type)}
                        helperText={getErrorMessage(error?.type, t)}
                        thousandSeparator=" "
                        allowNegative={false}
                        isAllowed={({ floatValue }) => (floatValue <= 999_999 && floatValue > 0) || isNil(floatValue)}
                        decimalScale={0}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">kr</InputAdornment>,
                            readOnly: isFetching,
                        }}
                        inputProps={{
                            inputMode: 'numeric',
                            autocomplete: 'off',
                        }}
                    />
                )}
            />
        </FormControl>
    )
}

export default ManadslonInput
