import { yupResolver } from '@hookform/resolvers/yup'
import { useScrollToError } from '@local/src/Hooks'
import { StudiestodApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { useForm } from 'react-hook-form'

import { defaultStudieintygFormModelValues, StudieintygFormModel } from '../Models/StudieintygFormModel'
import { StudieintygFormModelSchema } from '../Schemas/StudieintygFormSchema'
import { LaddaUppStudieintygEpiContent } from '../StudiestodStudieintyg.content'

export const useStudieintygForm = (data: StudiestodApiModel, epiContent: LaddaUppStudieintygEpiContent) => {
    const methods = useForm<StudieintygFormModel>({
        values: defaultStudieintygFormModelValues,
        resolver: yupResolver(StudieintygFormModelSchema(data, epiContent)),
        mode: 'onChange',
    })

    const {
        formState: { errors, isSubmitted },
    } = methods

    useScrollToError(errors, isSubmitted)

    return methods
}
