import { FileListError, FileValidation, ValidFileExtensions } from '@local/src/Hooks/useFilePicker'
import { isNil } from 'ramda'
import { useEffect, useState } from 'react'

export const studieintygFilenameRegex = new RegExp('^[A-Za-z0-9åäöÅÄÖ_ -]{1,254}\\.[a-zA-Z0-9]{1,5}$')
export const studieintygFileSizeLimitInKb = 5120
export const studieintygValidFileFormats: ValidFileExtensions[] = ['doc', 'docx', 'jpeg', 'jpg', 'pdf', 'png']

export const useStudieintygFilePicker = () => {
    const [selectedFiles, setSelectedFiles] = useState<File[]>([])
    const [fileListError, setFileListError] = useState<FileListError>(undefined)
    const [allFileListError, setAllFileListError] = useState<FileListError>(undefined)
    const [customFileListError, setCustomFileListError] = useState<FileValidation[]>([])

    useEffect(() => {
        const fileErrors = fileListError?.filesValidation ?? []
        const customErrors = customFileListError ?? []

        const allErrors = [...fileErrors, ...customErrors]

        if (allErrors.length > 0) {
            setAllFileListError(new FileListError('', allErrors, studieintygValidFileFormats, studieintygFileSizeLimitInKb))
        } else {
            setAllFileListError(undefined)
        }
    }, [customFileListError, fileListError])

    const hasError = !isNil(allFileListError)

    const onPick = (event: FileList) => {
        const fileErrors: FileValidation[] = []

        const filteredFiles = Array.from(event).filter((file) => {
            const normalizeFileNameFile: File = new File([file], file.name.normalize(), {
                type: file.type,
                lastModified: file.lastModified,
            })

            const isValid: boolean = studieintygFilenameRegex.test(normalizeFileNameFile.name)
            if (isValid) {
                return normalizeFileNameFile
            }

            const error: FileValidation = {
                file: normalizeFileNameFile,
                validation: {
                    type: 'custom',
                    message: 'Failed post-validation onPick',
                },
            }
            fileErrors.push(error)
        })

        if (fileErrors.length > 0) {
            setCustomFileListError(fileErrors)
        } else {
            setCustomFileListError([])
        }

        if (filteredFiles.length > 0) {
            setSelectedFiles((prevstate) => [...prevstate, ...filteredFiles])
        }
    }

    const onError = (error: FileListError) => {
        if (error?.filesValidation.length > 0) {
            setFileListError(error)
        } else {
            setFileListError(undefined)
        }
    }

    const clearFile = (index: number) => {
        setSelectedFiles([...selectedFiles.slice(0, index), ...selectedFiles.slice(index + 1)])
    }

    const resetFiles = () => {
        setSelectedFiles([])
        setCustomFileListError([])
        setFileListError(undefined)
    }
    return { selectedFiles, allFileListError, hasError, onError, onPick, clearFile, resetFiles }
}
