import { mergeDeepLeft } from 'ramda'
import { useEpiContent } from '@trr/app-shell-data'

import { IStudiestodEpiContent } from '../Types/StudiestodEpiContent'
import { epiFallbackContent } from '../Utils/Helpers/ContentHelper'

const usePickEpiContentWithFallback = () => {
    const content = useEpiContent<IStudiestodEpiContent>()

    return mergeDeepLeft(content, epiFallbackContent)
}

export default usePickEpiContentWithFallback
