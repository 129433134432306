import { log_info } from '@local/src/Utils/Helpers/logger'
import { HubConnection, HubConnectionState } from '@microsoft/signalr'
import { useEffect, useState } from 'react'

interface Props {
    hub: HubConnection
    groupName: string
}

const useSignalRJoinGroup = ({ hub, groupName }: Props) => {
    const [hubConnectionState, setHubConnectionState] = useState<HubConnectionState>(hub?.state ?? HubConnectionState.Disconnected)

    useEffect(() => {
        setHubConnectionState(hub?.state)
    }, [hub?.state])

    useEffect(() => {
        if (hubConnectionState !== HubConnectionState.Connected) return
        log_info('AddToGroup (hub method invoke) effect', { hubConnectionState, groupName })
        void hub.invoke('AddToGroup', groupName)

        return () => {
            if (hub && hub.state === HubConnectionState.Connected) {
                log_info('AddToGroup (hub method invoke) effect cleanup', { hubConnectionState, groupName })
                void hub.invoke('RemoveFromGroup', groupName)
            }
        }
    }, [hub, groupName, hubConnectionState])
}

export default useSignalRJoinGroup
