import Loader from '@local/src/Components/Loader'
import useCommonTranslation from '@local/src/Hooks/useCommonTranslation'
import {
    Backdrop,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    open: boolean
    onCancel: () => void
    onConfirm: (orsak: string) => void
    isPostingDelete: boolean
}

const AnsokanItemCardDeleteDialog = ({ open, onCancel, onConfirm, isPostingDelete }: Props) => {
    const [selectedOrsak, setSelectedOrsak] = useState('')
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokanList.ansokanItemCardDeleteDialog' })
    const { tCommon } = useCommonTranslation()

    const orsakOptions = [
        { id: 1, label: t('orsak_1') },
        { id: 2, label: t('orsak_2') },
        { id: 3, label: t('orsak_3') },
    ]

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedOrsak(event.target.value)
    }

    const handleOnConfirm = () => {
        onConfirm(selectedOrsak)
    }

    const deleteButtonIsDisabled = !selectedOrsak || isPostingDelete

    return (
        <Dialog PaperProps={{ 'data-trackable': true }} open={open} onClose={onCancel} data-testid="ansokan-item-card-delete-dialog">
            <DialogTitle>{t('title')}</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <DialogContentText>{t('contentText')}</DialogContentText>
                    <FormControl fullWidth>
                        <InputLabel id="ansokan-delete-orsak-label">Orsak</InputLabel>
                        <Select
                            labelId="ansokan-delete-orsak-label"
                            id="ansokan-delete-orsak-select"
                            value={selectedOrsak}
                            label="Orsak"
                            onChange={handleChange}
                        >
                            {orsakOptions.map(({ id, label }) => (
                                <MenuItem key={id} value={label}>
                                    {label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="text" data-testid="ansokan-item-card-delete-dialog-cancel-button" onClick={onCancel}>
                    {tCommon('button_cancel')}
                </Button>
                <Button
                    disabled={deleteButtonIsDisabled}
                    variant="text"
                    data-testid="ansokan-item-card-delete-dialog-confirm-button"
                    onClick={handleOnConfirm}
                >
                    {tCommon('button_delete')}
                </Button>
            </DialogActions>
            <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isPostingDelete}>
                <Loader />
            </Backdrop>
        </Dialog>
    )
}

export default AnsokanItemCardDeleteDialog
