import { isNil } from 'ramda'
import { useEffect } from 'react'
import { DeepRequired, FieldErrorsImpl } from 'react-hook-form'

const useScrollToError = <T extends object>(errors: FieldErrorsImpl<DeepRequired<T>>, isSubmitted: boolean) => {
    useEffect(() => {
        if (Object.keys(errors).length > 0 && isSubmitted) {
            const muiErrors = document.getElementsByClassName('Mui-error')

            const firstError = muiErrors.item(0)

            if (isNil(firstError)) {
                return
            }

            const yOffset = -200
            const y = firstError.getBoundingClientRect().top + window.scrollY + yOffset

            window.scrollTo({ top: y, behavior: 'smooth' })
        }
    }, [errors, isSubmitted])
}

export default useScrollToError
