import { yupResolver } from '@hookform/resolvers/yup'
import { useScrollToError } from '@local/src/Hooks'
import { useForm } from 'react-hook-form'

import { StudiestodAnsokanFormData } from '../Models/StudiestodAnsokanForm.types'
import { getStudiestodAnsokanFormSchema, getStudiestodAnsokanFormWithAnsokanNamnSchema } from '../Schemas/StudiestodAnsokanFormSchema'
import { StudiestodAnsokanFormEpiContent } from '../StudiestodAnsokanForm.content'

export const useStudiestodAnsokanForm = (
    ansokan: StudiestodAnsokanFormData,
    isRedigerbar: boolean,
    epiContent: StudiestodAnsokanFormEpiContent,
    isEdit: boolean
) => {
    const methods = useForm<StudiestodAnsokanFormData>({
        values: {
            ...ansokan,
            isReadonly: !isRedigerbar,
            shouldPublish: false,
        },
        resolver: (data: StudiestodAnsokanFormData, context, options) => {
            const isEditAndAnsokanNameIsLessThanLimit = isEdit && ansokan?.ansokanNamn?.length <= 50
            const isCreate = !isEdit

            const useV2Schema = isCreate || isEditAndAnsokanNameIsLessThanLimit
            const currentSchema = useV2Schema
                ? getStudiestodAnsokanFormWithAnsokanNamnSchema(data.shouldPublish, epiContent.valideringsmeddelanden)
                : getStudiestodAnsokanFormSchema(data.shouldPublish, epiContent.valideringsmeddelanden)
            return yupResolver(currentSchema)(data, context, options)
        },
        mode: 'onChange',
        shouldFocusError: true,
    })

    const {
        formState: { errors, isSubmitted },
    } = methods

    useScrollToError(errors, isSubmitted)

    return methods
}
