import { JaNejRadioButtonGroup } from '@local/src/Components/FormComponents'
import usePickEpiContentWithFallback from '@local/src/Hooks/usePickEpiContentWithFallback'
import { Typography } from '@mui/material'
import React, { Fragment } from 'react'

const ChecklistaStudieintyg = () => {
    const {
        studiestodStudieintyg: { laddauppstudieintyg: content },
    } = usePickEpiContentWithFallback()
    return (
        <Fragment>
            <Typography variant="h3" mb={2}>
                {content?.checklistForStudieintygHeader}
            </Typography>
            <JaNejRadioButtonGroup
                name="isFornamnEfternamnVisible"
                label={content?.checklistForStudieintygRadioButtonSynsFornamnOchEfternamnHeader}
            />
            <JaNejRadioButtonGroup
                name="isPersonnummerIncluded"
                label={content?.checklistForStudieintygRadioButtonSynsPersonnummer}
                valueType="boolean"
            />
            <JaNejRadioButtonGroup
                name="isUtbildningensNamnIncluded"
                label={content?.checklistForStudieintygRadioButtonFinnsUtbildningsnamn}
                valueType="boolean"
            />
            <JaNejRadioButtonGroup
                name="isStudieomfattningenIncluded"
                label={content?.checklistForStudieintygRadioButtonFinnsStudieomfattning}
                tooltip={content?.checklistForStudieintygRadioButtonFinnsStudieomfattningTooltip}
                valueType="boolean"
            />
            <JaNejRadioButtonGroup
                name="isDatumForTerminsstartAndTerminsslutIncluded"
                label={content?.checklistForStudieintygRadioButtonTerminstartAndTerminslut}
                tooltip={content?.checklistForStudieintygRadioButtonTerminstartAndTerminslutTooltip}
                valueType="boolean"
            />
            <JaNejRadioButtonGroup
                name="isStudieintygetDateratTidigastBeforeTerminStartIncluded"
                label={content?.checklistForStudieintygRadioButtonDateratTidigastBeforeAktuellTerminStart}
                valueType="boolean"
            />
        </Fragment>
    )
}

export default ChecklistaStudieintyg
