import { StudiestodApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import React from 'react'
import { Stack } from '@mui/material'

import ChecklistaStudieintyg from './Components/ChecklistaStudieintyg/ChecklistaStudieintyg'
import DescriptionStudieintyg from './Components/DescriptionStudieintyg/DescriptionStudieintyg'
import InformationStudieintyg from './Components/InformationStudieintyg/InformationStudieintyg'
import UploadStudieintyg from './Components/UploadStudieintyg/UploadStudieintyg'

interface Props {
    ansokan: StudiestodApiModel
}
const StudieintygForm = ({ ansokan }: Props) => (
    <Stack spacing={2} maxWidth={'md'}>
        <DescriptionStudieintyg ansokan={ansokan} />
        <InformationStudieintyg ansokan={ansokan} />
        <ChecklistaStudieintyg />
        <UploadStudieintyg />
    </Stack>
)
export default StudieintygForm
