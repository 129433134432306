import React from 'react'

import { SelectOption } from './Dropdown.types'
import Dropdown from './Dropdown'

interface Props {
    label: string
    name: string
    rangeFrom?: number
    rangeLength?: number
    reversed?: boolean
    valueAsNumber?: boolean
}

const RangeDropdown = ({ name, label, rangeFrom = 1, rangeLength = 10, reversed = false, valueAsNumber = false }: Props) => {
    const rangeArray = reversed
        ? Array.from({ length: rangeLength }, (_, i) => i + rangeFrom).reverse()
        : Array.from({ length: rangeLength }, (_, i) => i + rangeFrom)

    const selectOptions = rangeArray.map(
        (val): SelectOption => ({
            label: val.toString(),
            value: valueAsNumber ? val : val < 10 ? `0${val}` : val.toString(),
        })
    )

    return <Dropdown selectOptions={selectOptions} label={label} name={name} testId={`range-dropdown-${name}`} />
}

export default RangeDropdown
