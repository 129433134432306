import { Box, SxProps, TextField, Theme } from '@mui/material'
import React from 'react'
import { useFormContext } from 'react-hook-form'

type InputType = 'password' | 'number' | 'text'

interface IInputField {
    name: string
    label: string
    placeholder?: string
    inputType?: InputType
    parseAsNumber?: boolean
    disabled?: boolean
    testId?: string
    wrapperSx?: SxProps<Theme>
}

export const InputField = ({
    name,
    label,
    placeholder,
    inputType,
    disabled,
    testId,
    parseAsNumber = true,
    wrapperSx = {},
}: IInputField) => {
    const { register, getFieldState } = useFormContext()
    const { error } = getFieldState(name)

    return (
        <Box sx={wrapperSx}>
            <TextField
                {...register(name, {
                    setValueAs: (val: string) => {
                        if (inputType !== 'number') return val
                        if (!parseAsNumber) return val
                        const intVal = parseFloat(val)
                        return isNaN(intVal) ? undefined : intVal
                    },
                })}
                inputProps={{
                    'data-testid': testId || name,
                    step: inputType === 'number' ? 'any' : undefined,
                }}
                type={inputType}
                label={label}
                placeholder={placeholder}
                disabled={disabled}
                error={Boolean(error?.message)}
                helperText={error?.message}
                fullWidth
            />
        </Box>
    )
}
