import StudiestodRoutes from '@local/src/Routing/StudiestodRoutes/StudiestodRoutes'
import { createTrrMuiTheme, initTranslations, reactQueryClient } from '@local/src/Utils/Helpers/appBootstrapper'
import { ThemeProvider } from '@mui/material'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React from 'react'
import { injectStyle } from 'react-toastify/dist/inject-style'
import { AppShellDataProps, AppShellDataProvider } from '@trr/app-shell-data'
import { GTMTracker } from '@trr/gtm-tracking'

import { ToasterContainer } from './Components/Toaster/Toaster'

injectStyle()
initTranslations()

const theme = createTrrMuiTheme()

const App = ({ appShellData }: { appShellData: AppShellDataProps }) => (
    <QueryClientProvider client={reactQueryClient}>
        <ThemeProvider theme={theme}>
            <AppShellDataProvider value={appShellData}>
                <GTMTracker mfName="studiestod">
                    <StudiestodRoutes />
                </GTMTracker>
            </AppShellDataProvider>
            <ReactQueryDevtools initialIsOpen={false} />
            <ToasterContainer />
        </ThemeProvider>
    </QueryClientProvider>
)

export default App
