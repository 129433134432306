import { IsKoptUtbildning, UtbildningApiModel, UtbildningLangdEnhet } from '@local/src/Services/SwaggerApi/data-contracts'
import { OnlyDigitsRegex } from '@local/src/Utils/Helpers/regexes'
import { isNil } from 'ramda'
import { SchemaOf, boolean as yupBoolean, mixed as yupMixed, number as yupNumber, object as yupObject, string as yupString } from 'yup'

import { ValideringsmeddelandenEpiContent } from './StudiestodAnsokanFormSchema.content'

export const StudiestodUtbildningsFormDraftSchema = (content: ValideringsmeddelandenEpiContent): SchemaOf<UtbildningApiModel> =>
    yupObject({
        anordnare: yupString().trim().required(content.anordnare).nullable(),
        antalTerminer: yupString().nullable(),
        antalTerminerStudiestod: yupNumber().nullable(),
        isKoptUtbildning: yupMixed().nullable(),
        omfattning: yupNumber().nullable(),
        studiestart: yupString()
            .nullable()
            .transform((studiestart: string) => {
                if (isNil(studiestart) || studiestart === 'Invalid Date') return undefined

                return new Date(studiestart).toISOString()
            }),
        utbildningLangdEnhet: yupMixed().nullable(),
        utbildningsnamn: yupString().trim().required(content.utbildningsnamn).nullable(),
        utbildningstyp: yupString().nullable(),
        //CSN
        avserSokaCsn: yupBoolean().nullable(),
        studiemedelsberattigadUtbildning: yupBoolean().nullable(),
        ejCsnAnledning: yupString().nullable(),
        kraverProvning: yupBoolean().nullable(),
        id: yupString().nullable(),
    })

export const StudiestodFormUtbildningSchema = (content: ValideringsmeddelandenEpiContent): SchemaOf<UtbildningApiModel> =>
    yupObject({
        anordnare: yupString().trim().required(content.anordnare).nullable(),
        antalTerminer: yupString()
            .required(content.antalTerminer)
            .nullable()
            .test('parseInt', 'Endast digitz yo', (antalTerminer) => OnlyDigitsRegex.test(antalTerminer))
            .test('min', content.antalTerminerMin, (antalTerminer) => parseFloat(antalTerminer?.replace(',', '.')) >= 0.5)
            .test('max', content.antalTerminerMax, (antalTerminer) => parseFloat(antalTerminer?.replace(',', '.')) <= 100),
        antalTerminerStudiestod: yupNumber().nullable(),
        isKoptUtbildning: yupMixed().oneOf<IsKoptUtbildning>([0, 1, 2]).required(content.koptUtbildning),
        omfattning: yupNumber().required(content.omfattning).nullable(),
        studiestart: yupString()
            .required(content.studiestart)
            .nullable()
            .transform((studiestart: string) => (studiestart ? new Date(studiestart).toISOString() : undefined)),
        utbildningLangdEnhet: yupMixed().oneOf<UtbildningLangdEnhet>([0, 1, 2]).required(content.utbildningLangdEnhet).nullable(),
        utbildningsnamn: yupString().trim().required(content.utbildningsnamn).nullable(),
        utbildningstyp: yupString().trim().required(content.utbildningstyp).nullable(),
        id: yupString().nullable(),
        //CSN
        avserSokaCsn: yupBoolean().nullable(),
        studiemedelsberattigadUtbildning: yupBoolean().nullable(),
        ejCsnAnledning: yupString().nullable(),
        kraverProvning: yupBoolean().nullable(),
    })

export const StudiestodFormLegacyUtbildningSchema = (content: ValideringsmeddelandenEpiContent): SchemaOf<UtbildningApiModel> =>
    StudiestodFormUtbildningSchema(content).concat(
        yupObject({
            avserSokaCsn: yupBoolean().required(content.avserSokaCsn).nullable(),
            studiemedelsberattigadUtbildning: yupBoolean().required(content.studiemedelsberattigadUtbildning).nullable(),
            ejCsnAnledning: yupString()
                .nullable()
                .when('avserSokaCsn', {
                    is: false,
                    then: (schema) => schema.trim().required(content.ejCsnAnledning),
                }),
            kraverProvning: yupBoolean().required(content.kraverProvning).nullable(),
        })
    )
