import { ChangeEvent, useCallback, useRef } from 'react'

export const validFileExtensions = [
    'doc',
    'docx',
    'gif',
    'jpeg',
    'jpg',
    'pdf',
    'png',
    'ppt',
    'pptx',
    'rtf',
    'txt',
    'webp',
    'xls',
    'xlsx',
] as const

export type ValidFileExtensions = typeof validFileExtensions[number]

export class FileListError extends Error {
    public filesValidation: FileValidation[]
    public allowedFileExtensions: readonly ValidFileExtensions[]
    public fileSizeLimit: number

    public constructor(
        message: string,
        filesValidation: FileValidation[],
        allowedFileExtensions: readonly ValidFileExtensions[],
        fileSizeLimit: number
    ) {
        super(message)
        this.filesValidation = filesValidation
        this.allowedFileExtensions = allowedFileExtensions
        this.fileSizeLimit = fileSizeLimit
    }
}

export interface Validation {
    type: 'extension' | 'size' | 'custom'
    message: string
}
export interface FileValidation {
    file: File
    validation?: Validation
}

export interface UseFilePickerOptions {
    /**
     * Used for validating the maximum size of a file. This value is set in kb
     * so 5120 is 5mb.
     */
    fileSizeLimit?: number
    /**
     * Validate which type of files is allowed, e.g. png, jpeg, tiff.
     */
    validExtensions?: readonly ValidFileExtensions[]
    /**
     * This handler will be invoked when a file has passed all validation tests.
     * Handler will be invoked with a FileList.
     */
    onPickValid?: (files: FileList) => void

    /**
     * This handler will be invoked when any file fails to pass all validation tests.
     * Handler will be invoked with an Error.
     */
    onPickError?: (fileListError: FileListError) => void
}

const validSize = (file: File, fileSizeLimit: number): boolean => {
    const size = file.size

    const sizeInKb = Math.floor(size / 1000)

    return sizeInKb <= fileSizeLimit
}

const validFileExtension = (file: File, extensions: readonly string[]): boolean => {
    const ext = file.name.split('.').pop()
    return extensions.includes(ext.toLowerCase())
}

const getAcceptedExtensions = (extensions: readonly string[]) => extensions.map((ext) => ''.concat('.', ext)).join(',')

const createFileListError = (
    message: string,
    fileErrors: FileValidation[],
    validFileExtensions: readonly ValidFileExtensions[],
    fileSizeLimit: number
) => new FileListError(message, fileErrors, validFileExtensions, fileSizeLimit)

export const getFileValidations = (files: FileList, fileSizeLimit: number, validExtensions: readonly ValidFileExtensions[]) => {
    const fileValidations: FileValidation[] = []

    Array.from(files).forEach((currentFile) => {
        const isValidSize = validSize(currentFile, fileSizeLimit)
        const isValidFileEnding = validFileExtension(currentFile, validExtensions)
        let validation: Validation = undefined

        if (!isValidSize) {
            validation = {
                type: 'size',
                message: `File sizes bigger than ${fileSizeLimit} is not allowed.`,
            }
        }
        if (!isValidFileEnding) {
            const validExtensionsString = validExtensions.join(', ')
            validation = {
                type: 'extension',
                message: `The file extension is not valid. Valid extensions are ${validExtensionsString}`,
            }
        }
        fileValidations.push({
            file: currentFile,
            validation: validation,
        })
    })

    return {
        fileErrors: fileValidations.filter((v) => v.validation !== undefined),
        fileSuccess: fileValidations.filter((v) => v.validation === undefined),
    }
}
/**
 * The useFilePicker hook renders an input-field next to the button rendered in the DOM.
 */
const useFilePicker = ({ fileSizeLimit = 5120, validExtensions = validFileExtensions, onPickValid, onPickError }: UseFilePickerOptions) => {
    const inputRef = useRef<HTMLInputElement>(null)

    const openFileBrowser = useCallback(() => {
        inputRef.current?.click()
    }, [])

    const resetInputField = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.value = null
        }
    }, [])

    const handleValidateFiles = (files: FileList) => {
        const { fileErrors, fileSuccess } = getFileValidations(files, fileSizeLimit, validExtensions)

        if (fileErrors.length > 0) {
            const firstError = fileErrors[0]
            onPickError(createFileListError(firstError.validation?.message, fileErrors, validExtensions, fileSizeLimit))
        } else {
            onPickError(undefined)
        }

        if (fileSuccess.length > 0) {
            const dt = new DataTransfer()
            fileSuccess.forEach((value) => dt.items.add(value.file))
            onPickValid(dt.files)
        } else {
            const dt = new DataTransfer()
            onPickValid(dt.files)
        }
        resetInputField()
    }

    const validateFiles = useCallback(handleValidateFiles, [fileSizeLimit, onPickError, onPickValid, resetInputField, validExtensions])

    const changeHandler = useCallback(
        ({ currentTarget: { files } }: ChangeEvent<HTMLInputElement>) => {
            if (files.length === 0) {
                return
            }
            validateFiles(files)
        },
        [validateFiles]
    )
    return {
        openFileBrowser,
        inputRef,
        acceptedExtensions: getAcceptedExtensions(validExtensions),
        changeHandler,
    }
}

export default useFilePicker
