import Icons from '@local/src/Components/Icons'
import { StudieintygListApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { formatDate, formatTime } from '@local/src/Utils/Helpers/formatDate'
import { Link, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { downloadDocumentById } from '../../../Services/DownloadDocumentById'

interface Props {
    arendeId: string
    ansokanId: string
    studieintygItem: StudieintygListApiModel
}
const StudieintygDocument = ({ arendeId, ansokanId, studieintygItem }: Props) => {
    const { t } = useTranslation('translation', { keyPrefix: 'gtmTracking.studieintyg' })
    const handleDownloadDocumentClicked = async () =>
        await downloadDocumentById(arendeId, ansokanId, studieintygItem.dokumentId, studieintygItem.fileName)
    return (
        <li data-testid="show-studieintyg-list-item">
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                <Link
                    data-gtm-label={t('downloadFile')}
                    underline="hover"
                    onClick={handleDownloadDocumentClicked}
                    data-testid="show-studieintyg-list-item-submit"
                    sx={{
                        cursor: 'pointer',
                    }}
                    flexDirection="row"
                    display="flex"
                >
                    <Icons.Download />

                    <Typography ml={1}>{studieintygItem.fileName}</Typography>
                </Link>
                <Typography>{formatDate(studieintygItem.uploadedAt).toString()}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography>({studieintygItem.originalFileName.toString()})</Typography>
                <Typography>{formatTime(studieintygItem.uploadedAt).toString()}</Typography>
            </Stack>
        </li>
    )
}

export default StudieintygDocument
