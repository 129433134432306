import { PageWrapper } from '@local/Components/PageWrapper'
import { StudiestodListApiModel } from '@local/Services/SwaggerApi/data-contracts'
import { usePickEpiContentWithFallback } from '@local/src/Hooks'
import useGetStudiestodAnsokanList from '@local/src/Hooks/API/useGetStudiestodAnsokanList'
import { Link, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { AnsokanItemCard, StudiestodSentModal } from './Components'
import NoResult from './Components/NoResult/NoResult'

const StudiestodAnsokanList = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokanList' })

    const { studiestodList: content } = usePickEpiContentWithFallback()

    const { studiestodList, isError, isFetching, isFetchedAfterMount } = useGetStudiestodAnsokanList()
    const isLoading = !isFetchedAfterMount && isFetching
    const hasAnsokanItems = !isLoading && studiestodList && studiestodList.length > 0

    return (
        <PageWrapper
            isLoading={isLoading}
            errorLoadingData={isError}
            errorText={t('errors.fetchFailedStudiestod')}
            testSelector="studiestod-list-wrapper"
            headerText={t('list', { context: 'aktuellTitle' })}
        >
            <Stack spacing={3}>
                <Typography variant="preamble">
                    {t('list', { context: 'aktuellDescription' })}
                    <Link ml={0.5} href={content.mittTrrReadMoreLink}>
                        {t('list', { context: 'aktuellDescriptionLinkText' })}
                    </Link>
                </Typography>
                {hasAnsokanItems ? (
                    <Stack spacing={3}>
                        {studiestodList.map((ansokan: StudiestodListApiModel) => (
                            <AnsokanItemCard key={ansokan.id} ansokan={ansokan} />
                        ))}
                    </Stack>
                ) : (
                    <NoResult />
                )}
            </Stack>
            <StudiestodSentModal />
        </PageWrapper>
    )
}

export default StudiestodAnsokanList
