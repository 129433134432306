import { RangeDropdown } from '@local/src/Components/FormComponents/Dropdown'
import { usePickEpiContentWithFallback } from '@local/src/Hooks'
import { Stack, Typography } from '@mui/material'
import React from 'react'

type KalenderhalvarType = 1 | 2 | 3 | 4
interface Props {
    content: string
    type: KalenderhalvarType
}
export const KalenderhalvarDropDown = ({ type, content }: Props) => (
    <RangeDropdown name={`veckorKalenderHalvar${type}`} label={content} rangeFrom={1} rangeLength={26} valueAsNumber />
)

export const KalenderhalvarHeader = ({ type, content }: Props) => (
    <Typography variant="h4">
        {content} {type}
    </Typography>
)

export const KalenderhalvarDropDownSection = ({ type }: { type: KalenderhalvarType }) => {
    const { studiestodRaknesnurra: epiContent } = usePickEpiContentWithFallback()
    return (
        <Stack spacing={1}>
            <KalenderhalvarHeader content={epiContent.kalenderhalvarText} type={type} />
            <KalenderhalvarDropDown type={type} content={epiContent.kalenderHalvarAntalVeckorLabelText} />
        </Stack>
    )
}
