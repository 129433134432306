import { ApiResponseModel } from '@local/src/Services/ApiResponseModel'
import { ReactQueryKey, studiestodKlientApi } from '@local/src/Services/apiEndpointHelper'
import { apiRequestTryCatchWrapper } from '@local/src/Services/apiRequestHelper'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'

interface DeleteStudiestodAnsokan {
    ansokanId: string
    orsak: string
}

const useDeleteStudiestodAnsokan = (klientId: string) => {
    const queryClient = useQueryClient()

    const [apiResponse, setApiResponse] = useState<ApiResponseModel<void>>(undefined)

    const deleteStudiestodAnsokan = useMutation({
        mutationFn: (params: DeleteStudiestodAnsokan) => {
            setApiResponse(undefined)
            return apiRequestTryCatchWrapper(
                'useDeleteStudiestodAnsokan',
                studiestodKlientApi.klientAnsokanDelete,
                klientId,
                params.ansokanId,
                params.orsak
            )
        },
        onSuccess: async (response) => {
            setApiResponse(response)
            await queryClient.invalidateQueries([ReactQueryKey.getStudiestodList, klientId])
        },
        onError: (response: { message?: string }) => {
            setApiResponse({
                hasError: true,
                isSuccess: false,
                error: {
                    status: 500,
                    errorMessage: response?.message,
                    errorType: 'Request Error',
                },
            })
        },
    })

    return { deleteStudiestodAnsokan, apiResponse }
}

export default useDeleteStudiestodAnsokan
