import { array as yupArray, boolean as yupBoolean, number as yupNumber, object as yupObject, SchemaOf, string as yupString } from 'yup'

import { StudiestodAnsokanFormData } from '../Models/StudiestodAnsokanForm.types'

import { StudiestodFormMotiveringSchema } from './StudiestodAnsokanFormMotiveringSchema'
import { ValideringsmeddelandenEpiContent } from './StudiestodAnsokanFormSchema.content'
import {
    StudiestodFormLegacyUtbildningSchema,
    StudiestodFormUtbildningSchema,
    StudiestodUtbildningsFormDraftSchema,
} from './StudiestodAnsokanFormUtbildningSchema'

export const getStudiestodAnsokanFormSchema = (shouldPublish: boolean, content: ValideringsmeddelandenEpiContent) =>
    shouldPublish ? StudiestodAnsokanFormSubmitSchema(content) : StudiestodAnsokanFormDraftSchema(content)

export const getStudiestodAnsokanFormWithAnsokanNamnSchema = (shouldPublish: boolean, content: ValideringsmeddelandenEpiContent) =>
    shouldPublish ? StudiestodAnsokanFormSubmitWithAnsokanNamnSchema(content) : StudiestodAnsokanFormDraftWithAnsokanNamnSchema(content)

export const StudiestodAnsokanFormDraftSchema = (content: ValideringsmeddelandenEpiContent): SchemaOf<StudiestodAnsokanFormData> =>
    yupObject({
        ansokanNamn: yupString().required(content.ansokanNamn).nullable().trim(),
        inkomst: yupNumber().nullable(),
        harIntygatUppgifter: yupBoolean().nullable(),
        motivering: yupObject(),
        utbildningar: yupArray().of(StudiestodUtbildningsFormDraftSchema(content)),
        utbildningsforutsattning: yupString().nullable(),
        villkor: yupString().nullable(),
        isLegacyAnsokan: yupBoolean(),
        isReadonly: yupBoolean(),
        shouldPublish: yupBoolean(),
    })

export const StudiestodAnsokanFormSubmitSchema = (content: ValideringsmeddelandenEpiContent): SchemaOf<StudiestodAnsokanFormData> =>
    yupObject({
        ansokanNamn: yupString().required(content.ansokanNamn).nullable().trim(),
        harIntygatUppgifter: yupBoolean().required(content.harIntygatUppgifter).nullable().oneOf([true], content.harIntygatUppgifter),
        motivering: StudiestodFormMotiveringSchema(content),
        utbildningar: yupArray()
            .required()
            .min(1, content.utbildningRequired)
            .of(StudiestodFormUtbildningSchema(content))
            .when('isLegacyAnsokan', {
                is: true,
                then: (schema) => schema.of(StudiestodFormLegacyUtbildningSchema(content)),
            }),
        utbildningsforutsattning: yupString().trim().required(content.utbildningsforutsattning).nullable(),
        villkor: yupString().nullable(),
        isLegacyAnsokan: yupBoolean(),
        isReadonly: yupBoolean(),
        shouldPublish: yupBoolean(),
        inkomst: yupNumber().nullable(),
    })

export const StudiestodAnsokanFormDraftWithAnsokanNamnSchema = (
    content: ValideringsmeddelandenEpiContent
): SchemaOf<StudiestodAnsokanFormData> =>
    StudiestodAnsokanFormDraftSchema(content).shape({
        ansokanNamn: AnsokanNamnSchema(content.ansokanNamn),
    })

export const StudiestodAnsokanFormSubmitWithAnsokanNamnSchema = (
    content: ValideringsmeddelandenEpiContent
): SchemaOf<StudiestodAnsokanFormData> =>
    StudiestodAnsokanFormSubmitSchema(content).shape({
        ansokanNamn: AnsokanNamnSchema(content.ansokanNamn),
    })

const AnsokanNamnSchema = (ansokanNamn: string) =>
    yupString().required(ansokanNamn).nullable().trim().max(50, 'Du behöver ange ett yrkesmål som är max 50 tecken')
