import { TFunction } from 'i18next'
import { LiteralUnion, RegisterOptions } from 'react-hook-form'

export const getErrorMessage = (
    error: LiteralUnion<keyof RegisterOptions, string>,
    t: TFunction<'translation', 'kompletterandeStudiestodRaknesnurraForm'>
) => {
    switch (error) {
        case 'required':
            return t('formErrors.manadslonRequired')
        case 'min':
            return t('formErrors.manadslonMin')
        case 'max':
            return t('formErrors.manadslonMax')
        default:
            return ''
    }
}
