import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Stack,
    Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { StudiestodAnsokanFormData } from '../../Models/StudiestodAnsokanForm.types'

import { VillkorEpiContent } from './Villkor.content'

interface Props {
    content: VillkorEpiContent
}

const Villkor = ({ content }: Props) => {
    const [showDialog, setShowDialog] = useState<boolean>(false)
    const [harIntygatUppgifterHasBeenReset, setHarIntygatUppgifterHasBeenReset] = useState<boolean>(false)
    const {
        formState: { isDirty, errors },
        setValue,
        control,
        watch,
    } = useFormContext<StudiestodAnsokanFormData>()
    const { harIntygatUppgifter, villkor, isReadonly } = watch()

    useEffect(() => {
        if (harIntygatUppgifter && !villkor) {
            setValue('villkor', content.forsakranModalDescription)
        }
    }, [harIntygatUppgifter, villkor, setValue, content])

    useEffect(() => {
        if (harIntygatUppgifterHasBeenReset) return
        if (!control._defaultValues.harIntygatUppgifter) {
            setHarIntygatUppgifterHasBeenReset(true)
            return
        }
        if (isDirty && control._defaultValues.harIntygatUppgifter === true) {
            setValue('harIntygatUppgifter', false)
            setHarIntygatUppgifterHasBeenReset(true)
        }
    }, [control._defaultValues.harIntygatUppgifter, harIntygatUppgifter, harIntygatUppgifterHasBeenReset, isDirty, setValue])

    const handleShowDialog = () => setShowDialog(true)
    const handleCloseDialog = () => setShowDialog(false)

    return (
        <Stack>
            <FormControl error={Boolean(errors?.harIntygatUppgifter)}>
                <FormControlLabel
                    control={
                        <Controller
                            name="harIntygatUppgifter"
                            control={control}
                            render={({ field }) => (
                                <Checkbox
                                    {...field}
                                    checked={field.value}
                                    data-testid="harIntygatUppgifter"
                                    color="primary"
                                    disabled={isReadonly}
                                />
                            )}
                        />
                    }
                    label={content.villkorText}
                />
                {errors?.harIntygatUppgifter && <FormHelperText>{errors?.harIntygatUppgifter?.message}</FormHelperText>}
            </FormControl>

            <Box>
                <Button variant="text" onClick={handleShowDialog} data-testid="villkor">
                    <Typography variant="h6">{content.forsakranButtonText}</Typography>
                </Button>
            </Box>

            <Dialog PaperProps={{ 'data-trackable': true }} open={showDialog} onClose={handleCloseDialog} data-testid="villkor-modal">
                <DialogTitle>{content.forsakranModalTitle}</DialogTitle>
                <DialogContent>
                    <Typography>{content.forsakranModalDescription}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>{content.forsakranButtonCloseText}</Button>
                </DialogActions>
            </Dialog>
        </Stack>
    )
}

export default Villkor
