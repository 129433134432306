import { Kompetens } from '@local/Components/Illustrations'
import { StudieAnsokanSentModel } from '@local/src/Features/StudiestodAnsokanForm/Models/StudiestodAnsokanForm.types'
import useCommonTranslation from '@local/src/Hooks/useCommonTranslation'
import { storageKeys } from '@local/src/Types/App.types'
import { getLocalStorageState, removeLocalStorageState } from '@local/src/Utils/Helpers/StorageHelper'
import { Button, Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const StudiestodSentModal = () => {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)
    const { t } = useTranslation('translation', { keyPrefix: 'studiestodAnsokanList.studiestodSentModal' })
    const { tCommon } = useCommonTranslation()

    useEffect(() => {
        const newAnsokan = getLocalStorageState<StudieAnsokanSentModel>(storageKeys.newAnsokanSubmitted)
        if (newAnsokan) {
            setShowConfirmationModal(true)
        }
    }, [setShowConfirmationModal])

    const handleCloseModal = () => {
        removeLocalStorageState(storageKeys.newAnsokanSubmitted)
        setShowConfirmationModal(false)
    }

    return (
        <Dialog
            PaperProps={{ 'data-trackable': true }}
            maxWidth="sm"
            fullWidth
            open={showConfirmationModal}
            onClose={handleCloseModal}
            data-testid="studiestod-sent-modal"
        >
            <DialogContent sx={{ p: 4 }}>
                <Stack spacing={3}>
                    <Kompetens />
                    <Typography variant="h2" align="center">
                        {t('title')}
                    </Typography>
                    <Typography align="center">{t('description')}</Typography>
                    <DialogActions>
                        <Button onClick={handleCloseModal}>{tCommon('button_close')}</Button>
                    </DialogActions>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export default StudiestodSentModal
