import { ManadslonInput, Results, StudietaktDropdown } from '@local/src/Features/KompletterandeStudiestodRaknesnurraForm/Components'
import { useDebouncedEffect } from '@local/src/Hooks'
import React, { useEffect, useRef, useState } from 'react'
import { Box, Fade, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FormProvider } from 'react-hook-form'
import { isEmpty } from 'ramda'
import useGTMTracking from '@local/src/Hooks/useGTMTracking'

import useBeraknaKompletterandeStudiestod from './Hooks/useBeraknaKompletterandeStudiestod'
import useKompletterandeStudiestodRaknesnurraForm from './Hooks/useKompletterandeStudiestodRaknesnurraForm'

const KompletterandeStudiestodRaknesnurraForm = () => {
    const { trackClick } = useGTMTracking()
    const { t: tGtm } = useTranslation('translation', { keyPrefix: 'gtmTracking.raknesnurra' })
    const { t } = useTranslation('translation', { keyPrefix: 'kompletterandeStudiestodRaknesnurraForm' })

    const formMethods = useKompletterandeStudiestodRaknesnurraForm()
    const {
        watch,
        formState: { errors },
        trigger,
    } = formMethods

    const [shouldBeraknaCurrentManadslon, setShouldBeraknaCurrentManadslon] = useState(false)
    const [isChanging, setIsChanging] = useState(false)

    const { studietakt, manadslon } = watch()
    const { data, isFetching, isFetchedAfterMount } = useBeraknaKompletterandeStudiestod(manadslon, shouldBeraknaCurrentManadslon)

    const hasPageBeenRendered = useRef(false)
    const hasNoValidationError = isEmpty(errors)
    const isCangingOrFetching = isFetching || isChanging
    const selectedStudietaktResult = data?.response?.find((item) => item.studietakt === studietakt)
    const showResults = data?.isSuccess && Boolean(selectedStudietaktResult) && hasNoValidationError && !isCangingOrFetching
    const showApiResponseError = data?.hasError && hasNoValidationError

    const handleSubmitForm = async () => {
        await trigger()
        if (!errors.manadslon && manadslon) {
            setShouldBeraknaCurrentManadslon(true)
            trackClick('button', { label: tGtm('berakna_kompletterandeStudiestod') })
        }
        setIsChanging(false)
        return
    }

    useDebouncedEffect(
        () => {
            void handleSubmitForm()
        },
        500,
        [manadslon]
    )

    useEffect(() => {
        if (hasPageBeenRendered.current) {
            setIsChanging(true)
        }

        hasPageBeenRendered.current = true
    }, [manadslon])

    useEffect(() => {
        if (isFetching) {
            setShouldBeraknaCurrentManadslon(false)
        }
        if (!isFetching && isFetchedAfterMount) {
            setIsChanging(false)
        }
    }, [isFetching, isFetchedAfterMount])

    return (
        <FormProvider {...formMethods}>
            <form data-testid="kompletterande-studiestod-raknesnurra-form">
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={5}>
                    <Box width={{ xs: '100%', sm: 5 / 12 }}>
                        <Stack spacing={5}>
                            <ManadslonInput isFetching={isFetching} />
                            <StudietaktDropdown isFetching={isFetching} />
                        </Stack>
                    </Box>
                    <Box sx={{ minHeight: '384px' }} width={{ xs: '100%', sm: 7 / 12 }}>
                        <Stack direction="column" spacing={1}>
                            <Stack
                                sx={{ minHeight: '308px' }}
                                justifyContent="center"
                                borderRadius={2}
                                bgcolor={(theme) => theme.palette.surface.purple}
                            >
                                <Results
                                    result={selectedStudietaktResult}
                                    showResults={showResults}
                                    showApiResponseError={showApiResponseError}
                                    showLoader={isCangingOrFetching}
                                />
                            </Stack>
                            {showResults && (
                                <Fade in>
                                    <Typography data-testid="results-explanation-text" whiteSpace="pre-line" variant="caption">
                                        {t('resultsExplanationText')}
                                    </Typography>
                                </Fade>
                            )}
                        </Stack>
                    </Box>
                </Stack>
            </form>
        </FormProvider>
    )
}

export default KompletterandeStudiestodRaknesnurraForm
