type MapToOutput<I, U = I> = (v: I) => U

export const formatCurrencySEK: MapToOutput<number, string> = (amount) =>
    new Intl.NumberFormat('sv-se', {
        style: 'currency',
        currency: 'SEK',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    })
        .format(amount)
        //eslint-disable-next-line
        .replace(/\,00([^\d])/g, '$1') // Edge bug where minimumFractionDigits is ignored
