/* eslint-disable @typescript-eslint/no-explicit-any */
export interface IRequestType {
    url?: string
    baseURL?: string
    fullUrl?: string
    initiated: any
    fulfilled: any
    rejected: any
    aborted?: any
    body?: any
    _fetch?: any
    appendSub?: boolean
    data?: any
    multipart?: boolean
    absolutePath?: string
    appendUrl?: string
    isPublicWebApi?: boolean
    isAnonymousRequest?: boolean
    isBlob?: boolean
    fileName?: string
}

export interface IFetchRequestType {
    id_token: string
    method?: string
    body?: any
    _fetch?: any
    requestUrl: string
    multipart?: boolean
    absolutePath?: string
    isPublicWebApi?: boolean
    isAnonymousRequest?: boolean
}

export interface ValidationErrorsResponse {
    validationErrors: ValidationErrors[]
}

export interface ValidationErrors {
    errorCode: string
    errorMessage: string
    propertyName: string
}

// https://datatracker.ietf.org/doc/html/rfc7807
export interface ProblemDetails {
    type: string
    title: string
    detail: string
    instance: string
    status: string
    errorType: string
}

export class ValidationError extends Error implements ProblemDetails, ValidationErrorsResponse {
    public error: string
    public validationErrors: ValidationErrors[]
    public type: string
    public title: string
    public detail: string
    public instance: string
    public status: string
    public errorType: string

    public constructor(problemDetails: ProblemDetails, validationErrorsResponse: ValidationErrorsResponse) {
        super(problemDetails.status)
        this.validationErrors = validationErrorsResponse.validationErrors
        this.type = problemDetails.type
        this.title = problemDetails.title
        this.detail = problemDetails.detail
        this.instance = problemDetails.instance
        this.status = problemDetails.status
        this.errorType = problemDetails.errorType
    }
}
