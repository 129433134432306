import Loader from '@local/src/Components/Loader'
import { Box, Typography } from '@mui/material'
import { BoxProps } from '@mui/material/Box/Box'
import React from 'react'

interface Props extends BoxProps {
    headerText?: string
    children: React.ReactNode | React.ReactNode[]
    descriptionText?: string
    errorLoadingData?: boolean
    errorText?: string
    isLoading?: boolean
    testSelector?: string
}

const PageWrapper = ({
    errorLoadingData = false,
    descriptionText,
    isLoading,
    errorText,
    headerText,
    testSelector = 'page-wrapper',
    children,
    ...props
}: Props) => {
    if (errorLoadingData) {
        return <p data-testid="page-wrapper-error">{errorText}</p>
    }

    return (
        <Box data-testid={testSelector} {...props}>
            {headerText && (
                <Typography variant="h1" gutterBottom>
                    {headerText}
                </Typography>
            )}
            {descriptionText && (
                <Typography variant="preamble" gutterBottom>
                    {descriptionText}
                </Typography>
            )}
            {isLoading ? (
                <div data-testid="page-loader">
                    <Loader />
                </div>
            ) : (
                <>{children}</>
            )}
        </Box>
    )
}

export default PageWrapper
