import InfoPopover from '@local/src/Components/InfoPopover'
import { usePickEpiContentWithFallback } from '@local/src/Hooks'
import { Button, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { FieldPath, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { RaknesnurraFormData } from '../../Models/RaknesnurraForm.types'

import { KalenderhalvarDropDown, KalenderhalvarDropDownSection, KalenderhalvarHeader } from './KalenderhalvarElements'

const Kalenderhalvar = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'gtmTracking.raknesnurra' })
    const [showKalenderhalvarInformation, setShowKalenderhalvarInformation] = useState(false)
    const { studiestodRaknesnurra: epiContent } = usePickEpiContentWithFallback()
    const { setValue, watch } = useFormContext<RaknesnurraFormData>()
    const { antalKalenderhalvar } = watch()

    const handleAddAntalKalenderhalvar = () => {
        if (antalKalenderhalvar === 4) {
            setShowKalenderhalvarInformation(true)
            return
        }
        setShowKalenderhalvarInformation(false)
        setValue('antalKalenderhalvar', antalKalenderhalvar + 1)
    }

    const handleRemoveAntalKalenderhalvar = () => {
        if (antalKalenderhalvar === 1) {
            setShowKalenderhalvarInformation(true)
            return
        }
        setShowKalenderhalvarInformation(false)
        const newAntal = antalKalenderhalvar - 1
        const propName = `veckorKalenderHalvar${antalKalenderhalvar}` as FieldPath<RaknesnurraFormData>
        setValue('antalKalenderhalvar', newAntal)
        setValue(propName, null)
    }

    return (
        <Stack spacing={3} data-testid="kalenderhalvar">
            <Stack spacing={1}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <KalenderhalvarHeader content={epiContent.kalenderhalvarText} type={1} />
                    <InfoPopover content={epiContent.kalenderhalvarHeaderTooltip} />
                </Stack>
                <KalenderhalvarDropDown type={1} content={epiContent.kalenderHalvarAntalVeckorLabelText} />
            </Stack>
            {antalKalenderhalvar > 1 && <KalenderhalvarDropDownSection type={2} />}
            {antalKalenderhalvar > 2 && <KalenderhalvarDropDownSection type={3} />}
            {antalKalenderhalvar > 3 && <KalenderhalvarDropDownSection type={4} />}

            <Stack spacing={2}>
                <Typography>{epiContent.addRemoveInformationText}</Typography>

                <Stack direction="row" spacing={2}>
                    <Button
                        data-testid="raknesnurra-form-add-kalender-halvar-button"
                        onClick={handleAddAntalKalenderhalvar}
                        variant="outlined"
                        data-gtm-label={t('kalenderHalvarLaggTill')}
                    >
                        {epiContent.kalenderHalvarAntalVeckorAddButtonText}
                    </Button>
                    <Button
                        data-testid="raknesnurra-form-remove-kalender-halvar-button"
                        onClick={handleRemoveAntalKalenderhalvar}
                        variant="outlined"
                        data-gtm-label={t('kalenderHalvarTaBort')}
                    >
                        {epiContent.kalenderHalvarAntalVeckorRemoveButtonText}
                    </Button>
                </Stack>
            </Stack>
            {showKalenderhalvarInformation && (
                <Typography color={(theme) => theme.palette.error.main}>
                    {antalKalenderhalvar === 4 ? epiContent.addKalenderHalvarInformation : epiContent.removeKalenderHalvarInformation}
                </Typography>
            )}
        </Stack>
    )
}

export default Kalenderhalvar
