import InfoPopover from '@local/src/Components/InfoPopover'
import { usePickEpiContentWithFallback } from '@local/src/Hooks'
import { formatCurrencySEK } from '@local/src/Utils/Helpers'
import { Stack, Typography } from '@mui/material'
import React, { Fragment } from 'react'

import { ResultatKalenderhalvar } from '../../Models/RaknesnurraForm.types'

interface Props {
    item: ResultatKalenderhalvar
}
const RaknesnurraResultKalenderhalvarItem = ({ item }: Props) => {
    const { studiestodRaknesnurra: epiContent } = usePickEpiContentWithFallback()
    const veckaOrVeckorContent = item.antalVeckor === 1 ? epiContent.veckaText : epiContent.veckorText
    const antalVeckorContent = `${item.antalVeckor} ${veckaOrVeckorContent}`
    const csnErsattningsnivaContent = epiContent.csnCoversErsattningsprocentText.replace(
        '{ersattningsniva}',
        item.ersattningsniva.toString()
    )
    return (
        <div data-testid={`raknesnurra-form-resultat-kalenderhalvar-${item.halvar}`}>
            {item.maxbeloppReached ? (
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography variant="h3">
                        {epiContent.kalenderhalvarText} {item.halvar}
                    </Typography>
                    <InfoPopover content={epiContent.resultatKalenderhalvarTooltip} />
                </Stack>
            ) : (
                <Typography variant="h3">
                    {epiContent.kalenderhalvarText} {item.halvar}
                </Typography>
            )}

            {item.csnCoversErsattningsprocent ? (
                <Typography>{csnErsattningsnivaContent}</Typography>
            ) : (
                <Fragment>
                    <Typography data-testid="raknesnurra-form-resultat-kalenderhalvar-weeks">{antalVeckorContent}</Typography>
                    <Typography data-testid="raknesnurra-form-resultat-kalenderhalvar-ersattning">
                        {formatCurrencySEK(item.ersattning.perDag)}
                        {epiContent.perDagText} | {formatCurrencySEK(item.ersattning.perVecka)}
                        {epiContent.perVeckaText}
                    </Typography>
                    <Typography data-testid="raknesnurra-form-resultat-kalenderhalvar-total">
                        {epiContent.totaltText} {formatCurrencySEK(item.ersattning.totalt)}
                    </Typography>
                </Fragment>
            )}
        </div>
    )
}

export default RaknesnurraResultKalenderhalvarItem
