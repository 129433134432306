import { Divider } from '@mui/material'
import React from 'react'

interface Props {
    isThinner?: boolean
}

export const Separator = ({ isThinner }: Props) => (
    <Divider
        sx={(theme) => ({
            borderWidth: 0,
            backgroundColor: theme.palette.grey[400],
            height: isThinner ? '1px' : '2px',
            marginTop: theme.spacing(isThinner ? 1 : 3),
            marginBottom: theme.spacing(isThinner ? 1 : 2),
        })}
    />
)
