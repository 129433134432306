import { DatePicker } from '@local/src/Components/FormComponents'
import { usePickEpiContentWithFallback } from '@local/src/Hooks'
import React from 'react'

const BeraknadStudiestart = () => {
    const { studiestodRaknesnurra: epiContent } = usePickEpiContentWithFallback()
    return (
        <DatePicker
            name="beraknadStudiestart"
            label={epiContent.studiestartLabelText}
            testId="raknesnurra-form-beraknadStudiestart"
            minDate={new Date('2015-06-15T00:00:00')}
        />
    )
}

export default BeraknadStudiestart
