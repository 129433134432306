import { useForm } from 'react-hook-form'

interface FormInputs {
    manadslon: number | null
    studietakt: number
}

const useKompletterandeStudiestodRaknesnurraForm = () => {
    const formMethods = useForm<FormInputs>({
        defaultValues: {
            manadslon: null,
            studietakt: 100,
        },
        reValidateMode: 'onSubmit',
    })

    return formMethods
}

export default useKompletterandeStudiestodRaknesnurraForm
