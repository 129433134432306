import { usePickEpiContentWithFallback } from '@local/src/Hooks'
import { Box, Stack, Typography } from '@mui/material'
import React from 'react'

interface Props {
    isYoungerThan40: boolean
    is65OrOlder: boolean
    isUnhandledError: boolean
}
const RaknesnurraInvalidResult = ({ isYoungerThan40, is65OrOlder, isUnhandledError }: Props) => {
    const { studiestodRaknesnurra: epiContent } = usePickEpiContentWithFallback()

    if (isUnhandledError) {
        return (
            <Box data-testid="raknesnurra-invalid-result-unhandled">
                <Typography color={(theme) => theme.palette.error.main}>{epiContent.berakningMisslyckadText}</Typography>
            </Box>
        )
    }
    return (
        <Stack spacing={2} data-testid="raknesnurra-invalid-result-saknar-rattigheter">
            {isYoungerThan40 && <Typography>{epiContent.isYoungerThan40Text}</Typography>}
            {is65OrOlder && <Typography>{epiContent.is65OrOlderText}</Typography>}
            <Typography>{epiContent.saknarRattigheterText}</Typography>
        </Stack>
    )
}

export default RaknesnurraInvalidResult
