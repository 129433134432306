import React from 'react'
import { useEpiPagePath } from '@trr/app-shell-data'

import StudiestodStudieintyg from '../Features/StudiestodStudieintyg/StudiestodStudieintyg'
import useStudieansokanUrlParams from '../Hooks/useStudieansokanUrlParams'

const StudiestodStudieintygPage = () => {
    const url = useEpiPagePath()
    const { params } = useStudieansokanUrlParams(url)
    return <StudiestodStudieintyg arendeId={params.arendeId} ansokanId={params.ansokanId} />
}

export default StudiestodStudieintygPage
