import { useStudieansokanUrlParams } from '@local/src/Hooks'
import { useStudiestodSignalR } from '@local/src/Hooks/SignalR'
import {
    StudieersattningRaknesnurraBlock,
    KompletterandeStudiestodRaknesnurraBlock,
    StudiestodAnsokanEditPage,
    StudiestodAnsokanListPage,
    StudiestodAnsokanPage,
    StudiestodStudieintygPage,
} from '@local/src/Views'
import { AppShellRouter, KeyRoute } from '@local/src/Routing/AppShellRouter'
import { appShellKey } from '@local/src/Types/App.types'
import { useCurrentKey, useSignalR, useEpiPagePath } from '@trr/app-shell-data'
import React from 'react'

const StudiestodRoutes = () => {
    const url = useEpiPagePath()
    const signalR = useSignalR()
    const klient = signalR?.klient
    const currentKey = useCurrentKey()

    const isStudiestodansokanForm = currentKey === appShellKey.studiestodFormKey
    const studieansokanUrlParams = useStudieansokanUrlParams(isStudiestodansokanForm && url)

    useStudiestodSignalR(klient)

    return (
        <AppShellRouter currentKey={currentKey}>
            <KeyRoute urlKey={appShellKey.kompletterandeStudiestodRaknesnurraKey}>
                <KompletterandeStudiestodRaknesnurraBlock />
            </KeyRoute>
            <KeyRoute urlKey={appShellKey.studiestodRaknesnurraKey}>
                <StudieersattningRaknesnurraBlock />
            </KeyRoute>
            <KeyRoute urlKey={appShellKey.studiestodStudieintygKey}>
                <StudiestodStudieintygPage />
            </KeyRoute>

            <KeyRoute urlKey={appShellKey.studiestodListKey}>
                <StudiestodAnsokanListPage />
            </KeyRoute>

            <KeyRoute urlKey={appShellKey.studiestodFormKey}>
                <StudiestodAnsokanPage />
            </KeyRoute>

            <KeyRoute isMatch={() => isStudiestodansokanForm && Boolean(studieansokanUrlParams?.isMatch)}>
                <StudiestodAnsokanEditPage />
            </KeyRoute>
        </AppShellRouter>
    )
}

export default StudiestodRoutes
