/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { EkonomisktStudiestodApiRequestModel, EkonomisktStudiestodApiResponseModel } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Raknesnurror<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Raknesnurror
     * @name RaknesnurrorEkonomisktStudiestodCreate
     * @request POST:/studiestod/raknesnurror/ekonomiskt-studiestod
     * @secure
     * @response `200` `(EkonomisktStudiestodApiResponseModel)[]` OK
     */
    raknesnurrorEkonomisktStudiestodCreate = (data: EkonomisktStudiestodApiRequestModel, params: RequestParams = {}) =>
        this.request<EkonomisktStudiestodApiResponseModel[], any>({
            path: `/studiestod/raknesnurror/ekonomiskt-studiestod`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params,
        })
}
