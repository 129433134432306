import { CreateAnsokanApiModel } from '@local/src/Services/SwaggerApi/data-contracts'
import { studiestodKlientApi } from '@local/src/Services/apiEndpointHelper'
import { apiRequestTryCatchWrapper } from '@local/src/Services/apiRequestHelper'

export const updateAnsokan = (klientId: string, ansokanId: string, shouldPublish: boolean, data: CreateAnsokanApiModel) =>
    apiRequestTryCatchWrapper(
        'updateAnsokanRequest',
        studiestodKlientApi.klientAnsokanUpdate,
        { klientId: klientId, id: ansokanId, shouldPublish: shouldPublish },
        data
    )
