import { ApiResponseModel } from '@local/Services/ApiResponseModel'
import { TrrValidationErrorField } from '@local/Services/SwaggerApi/data-contracts'
import useCommonTranslation from '@local/src/Hooks/useCommonTranslation'
import { styled } from '@mui/material'
import { isNil } from 'ramda'
import React, { Fragment, useEffect } from 'react'

import { pushFeedback, ToasterContainer } from '../Toaster/Toaster'

const ErrorMessage = styled('p')(({ theme }) => ({
    color: theme.palette.error.main,
}))

const ErrorListHeader = styled('li')(({ theme }) => ({
    color: theme.palette.error.main,
}))

interface Props {
    apiResponse: ApiResponseModel<unknown>
    successMessage?: string
    failureMessage?: string
    useCustomError?: boolean
    children?: React.ReactNode | React.ReactNode[]
}

const SubmitResponse = ({
    apiResponse: apiResponse,
    successMessage = 'Sparning lyckades',
    failureMessage = 'Sparning misslyckades',
    useCustomError = false,
    children,
}: Props) => {
    const { tCommon } = useCommonTranslation()

    useEffect(() => {
        if (apiResponse !== undefined) {
            const message = apiResponse.isSuccess ? successMessage : failureMessage
            pushFeedback({
                body: message,
                testSelector: apiResponse.isSuccess ? 'toaster-confirmation' : 'toaster-error',
                isSuccess: apiResponse.isSuccess,
            })
        }
    }, [apiResponse, failureMessage, successMessage])

    if (!apiResponse) return <></>

    if (useCustomError && !isNil(children))
        return (
            <Fragment>
                {children} <ToasterContainer />
            </Fragment>
        )

    return (
        <Fragment>
            {!apiResponse.isSuccess && (
                <pre data-testid="api-error-response">
                    <ErrorMessage data-testid="api-error-response-header">{tCommon('generic_error')}</ErrorMessage>
                    {apiResponse.error?.validationErrors?.map((err: TrrValidationErrorField) => (
                        <ErrorListHeader key={err.propertyName}>
                            - {err.propertyName} <ErrorMessage>{err.errorMessage}</ErrorMessage>
                        </ErrorListHeader>
                    ))}
                </pre>
            )}
        </Fragment>
    )
}

export default SubmitResponse
