import { isNil } from 'ramda'
import { match, useRouteMatch } from 'react-router-dom'

interface StudiestodansokanParams {
    arendeId: string
    ansokanId: string
}

interface UseStudieansokanUrlParams extends match<StudiestodansokanParams> {
    isMatch: boolean
}

const useStudieansokanUrlParams = (baseUrl = ''): UseStudieansokanUrlParams => {
    const match = useRouteMatch<StudiestodansokanParams>(`${baseUrl}:arendeId/:ansokanId`)
    const noMatchResponse = {
        isMatch: false,
        isExact: false,
        params: {
            ansokanId: '',
            arendeId: '',
        },
        path: '',
        url: '',
    }
    if (!baseUrl) return noMatchResponse

    return isNil(match) ? noMatchResponse : { ...match, isMatch: true }
}
export default useStudieansokanUrlParams
